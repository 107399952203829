@import '../includes';

/* Material Tooltip style overwrite here */
.mat-tooltip {
  font-size: 12px;
  background: $text-grey-secondary;
  position: relative;
  &::after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid $text-grey-secondary;
  }
  &.below {
    overflow: initial;
    margin-top: 1rem;
    &:after {
      top: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(0);
    }
  }

  &.above {
    overflow: initial;
    margin-bottom: 1rem;
    &:after {
      bottom: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(180deg);
    }
  }

  &.right {
    overflow: initial;
    margin-left: 1rem;
    &:after {
      left: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(270deg);
    }
  }

  &.left {
    overflow: initial;
    margin-right: 1rem;
    &:after {
      right: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(90deg);
    }
  }
}
.example-tooltip-black {
  background: $text-grey-primary;
}
