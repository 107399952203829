@import '../includes';

/* Mat Snackbar style overwrite here */
.mat-snack-bar-container {
  box-shadow: 0px 13px 16px 0px rgba(0, 0, 0, 0.1);
  border-left: 5px solid;
  padding: 12px 16px !important;
  min-width: 405px !important;
  background: #fff;
  border-radius: 8px !important;

  .info-icon {
    display: inline-table;
    padding: 6px;
  }

  .close-icon {
   color: #85888E;
  }

  p {
    @include font(16px, 22px, 500, #0C121D);
    letter-spacing: -0.408px;
  }

  &.success-snack-bar {
    border-color: #00AF62;

    .info-icon {
      color: #00AF62;
      background-color: #F0FAF6;
    }
  }

  &.error-snack-bar {
    border-color: #E00034;

    .info-icon {
      color: #E00034;
      background-color: #FDF0F3;
    }
  }

  &.warning-snack-bar {
    border-color: rgb(255, 204, 0);

    .info-icon {
      color: rgb(255, 204, 0);
      background-color: rgba(255, 204, 0, 0.2);
    }
  }
}
