@charset "UTF-8";
/* ==============================
   CHECKOUT SPECIFIC COLORS
---------------------------------*/
:root {
  --primary-color: rgb(133, 0, 65);
  --primary-color-85: rgba(133, 0, 65, 0.85);
  --primary-color-70: rgba(133, 0, 65, 0.7);
  --primary-color-50: rgba(133, 0, 65, 0.5);
  --primary-color-40: rgba(133, 0, 65, 0.4);
  --primary-color-20: rgba(133, 0, 65, 0.2);
  --primary-color-16: rgba(133, 0, 65, 0.16);
  --primary-color-15: rgba(133, 0, 65, 0.15);
  --primary-color-12: rgba(133, 0, 65, 0.12);
  --primary-color-10: rgba(133, 0, 65, 0.1);
  --primary-color-06: rgba(133, 0, 65, 0.06);
  --primary-color-05: rgba(133, 0, 65, 0.05);
  --primary-color-03: rgba(133, 0, 65, 0.03);
  --primary-light: #f2e5ec;
  --secondary-color: rgb(103, 0, 51);
  --secondary-color-04: rgba(103, 0, 51, 0.04);
  --background-color: #f9f2f5;
  --background-light-blue: #f2f6f9;
  --bg-light-white: #F7FAFC;
  --bg-reddish-orange: #fff1e6;
  --bg-light-grey: #E6EBEE;
  --text-primary: #0C121D;
  --text-tertiary: #61656C;
  --text-warn: #E88B00;
  --text-light: #aaacb0;
  --text-hint: #85888E;
  --text-orange: #ff7700;
  --text-dark-grey: #3D414A;
  --text-white-primary: #ffffff;
  --text-grey-primary-1: #03011e;
  --text-grey-primary-2: #434850;
  --text-grey-primary-3: #11161f;
  --text-grey-primary-4: #424242;
  --text-grey-secondary: rgb(66, 66, 66);
  --text-grey-secondary-50: rgba(66, 66, 66, 0.5);
  --text-grey-secondary-1: rgb(74, 74, 74);
  --text-grey-secondary-1-08: rgba(74, 74, 74, 0.08);
  --text-grey-hint: rgb(155, 155, 155);
  --text-grey-hint-20: rgba(155, 155, 155, 0.2);
  --text-grey-content: rgb(127, 127, 127);
  --text-grey-content-10: rgba(127, 127, 127, 0.1);
  --text-grey-line: rgb(187, 186, 186);
  --text-grey-line-65: rgba(187, 186, 186, 0.65);
  --text-grey-progress: #838383;
  --grey-darken-1: #333333;
  --grey-darken-2: #666666;
  --grey-darken-3: #999999;
  --grey-darken-4: #18162d;
  --grey-darken-5: #22293d;
  --grey-darken-6: #e1e1e1;
  --grey-darken-7: #e1e1e163;
  --grey-darken-8: #06172c;
  --grey-lighten-1: #cccccc;
  --grey-lighten-2: #e2e2e2;
  --grey-lighten-3: #f7f7f7;
  --grey-lighten-5: #f0f0f0;
  --grey-lighten-4: #e9ebee;
  --grey-lighten-6: #d7d7d7;
  --grey-lighten-7: #7f7f7f;
  --grey-lighten-8: #e5e5e5;
  --grey-lighten-9: #8a8d92;
  --grey-lighten-10: #ecf9fa;
  --grey-lighten-11: #d9d9d9;
  --grey-lighten-12: #b9babd;
  --grey-lighten-13: #5b5f66;
  --grey-lighten-2-60: rgba(226, 226, 226, 0.6);
  --grey-lighten-2-50: rgba(226, 226, 226, 0.5);
  --grey-lighten-2-16: rgba(226, 226, 226, 0.16);
  --grey-lighten-2-05: rgba(226, 226, 226, 0.05);
  --grey-lighten-6-01: rgba(215, 215, 215, 0.01);
  --skeleton-loader: #f6f7f9;
  --dividerGrey: rgba(0, 0, 0, 0.3);
  --black-opacity-10: rgba(0, 0, 0, 0.1);
  --dividerPurple: rgba(100, 28, 255, 0.19);
  --red: rgb(224, 0, 52);
  --red-40: rgba(224, 0, 52, 0.4);
  --red-20: rgba(224, 0, 52, 0.2);
  --red-16: rgba(224, 0, 52, 0.16);
  --red-15: rgba(224, 0, 52, 0.15);
  --red-14: rgba(224, 0, 52, 0.14);
  --red-10: rgba(224, 0, 52, 0.1);
  --red-08: rgba(224, 0, 52, 0.08);
  --red-05: rgba(224, 0, 52, 0.05);
  --red-lighten1: rgb(255, 166, 160);
  --red-lighten2: rgb(255, 240, 240);
  --red-lighten3: rgba(224, 0, 52, 0.08);
  --green-lighten-bg: rgba(224, 245, 236, 1);
  --green: rgb(0, 173, 98);
  --green-50: rgba(0, 173, 98, 0.5);
  --green-40: rgba(0, 173, 98, 0.4);
  --green-20: rgba(0, 175, 98, 0.2);
  --green-16: rgba(0, 173, 98, 0.16);
  --green-15: rgba(0, 173, 98, 0.15);
  --green-10: rgba(0, 173, 98, 0.1);
  --green-05: rgba(0, 173, 98, 0.05);
  --green-04: rgba(0, 175, 98, 0.04);
  --green-03: rgba(0, 175, 98, 1);
  --green-lighten1: #7ed321;
  --green-lighten2: rgb(188, 238, 185);
  --green-lighten2-40: rgba(188, 238, 185, 0.4);
  --green-lighten3: rgba(0, 175, 99, 0.15);
  --green-dark: rgba(0, 175, 99, 100);
  --blue: rgb(10, 161, 251);
  --blue-40: rgba(10, 161, 251, 0.4);
  --blue-20: rgba(10, 161, 251, 0.2);
  --blue-16: rgba(10, 161, 251, 0.16);
  --blue-15: rgba(10, 161, 251, 0.15);
  --blue-05: rgba(10, 161, 251, 0.05);
  --blue-12: rgba(10, 161, 251, 0.12);
  --blue-01: rgba(10, 161, 251, 1);
  --blue-lighten1: #9cddfa;
  --blue-lighten2: #e3f2fd;
  --blue-lighten3: rgba(255, 119, 0, 0.15);
  --blue-lighten4: #00abbb;
  --blue-lighten5: #007883;
  --blue-lighten6: #f2f7fa;
  --blue-bg-light: #F0FAFB;
  --warn-color: rgb(252, 87, 94);
  --warn-color-50: rgba(252, 87, 94, 0.5);
  --warning-color-dark: #fc854a;
  --warning-color-lighten: #ffcc00;
  --pink-color: #fd73a1;
  --yellow: rgb(255, 194, 51);
  --yellow-20: rgba(255, 194, 51, 0.2);
  --pink-legend-color: rgba(224, 0, 52, 0.2);
  --dark-green: rgb(0, 171, 187);
  --dark-green-80: rgb(0, 171, 187, 0.8);
  --dark-green-20: rgb(0, 171, 187, 0.2);
  --dark-green-16: rgb(0, 171, 187, 0.16);
  --dark-green-14: rgb(0, 171, 187, 0.14);
  --dark-green-12: rgb(0, 171, 187, 0.12);
  --dark-green-10: rgb(0, 171, 187, 0.1);
  --dark-green-lighten: rgba(0, 171, 187, 0.06);
  --orange-color: rgb(255, 119, 0);
  --orange-color-40: rgba(255, 119, 0, 0.4);
  --orange-color-20: rgba(255, 119, 0, 0.2);
  --orange-color-16: rgba(255, 119, 0, 0.16);
  --orange-color-15: rgba(255, 119, 0, 0.15);
  --orange-color-14: rgba(255, 119, 0, 0.14);
  --orange-color-10: rgba(255, 119, 0, 0.1);
  --orange-color-05: rgba(255, 119, 0, 0.05);
  --light-green: #4cd964;
  --dark-green-63: #00af63;
  --light-green-12: rgba(0, 175, 99, 0.12);
  --peak-green: rgb(0, 127, 139);
  --peak-green-15: rgba(0, 127, 139, 0.15);
  --peak-green-10: rgba(0, 127, 139, 0.1);
  --peak-green-08: rgba(0, 127, 139, 0.08);
  --peak-green-05: rgba(0, 127, 139, 0.05);
  --dark-pink: rgb(189, 16, 224);
  --dark-pink-15: rgba(189, 16, 224, 0.15);
  --dark-pink-05: rgba(189, 16, 224, 0.05);
  --dark-yellow: rgb(255, 188, 0);
  --dark-yellow-15: rgba(255, 188, 0, 0.15);
  --dark-yellow-05: rgba(255, 188, 0, 0.05);
  --dark-red: rgb(208, 2, 27);
  --dark-red-05: rgba(208, 2, 27, 0.05);
  --royal-blue: rgb(0, 19, 255);
  --royal-blue-05: rgba(0, 19, 255, 0.05);
  --dark-blue: rgb(0, 82, 130);
  --dark-blue-05: rgba(0, 82, 130, 0.05);
  --darken-blue: #0066a1;
  --border-grey: #e3e3e3;
  --border-light-grey: #E7E7E8;
  --border-grey-1: #E7E7E8;
  --light-grey-colors: #aaa;
  --dot-1: #C2C4C6;
  /*===============================
    Social Colors
  ----------------------------------*/
  --fb-color: #3b5998;
  --tw-color: #55acee;
  --gplus-color: #dd4b39;
  --yt-color: #cd201f;
  --li-color: #0082ca;
  --pin-color: #c61118;
  --ins-color: #3f729b;
  --git-color: #4183c4;
  --comm-color: #30cfc0;
  --vk-color: #4c75a3;
  --drib-color: #c32361;
  --red-color-34: #e00034;
  --error-red: rgba(224, 0, 52, 0.12);
  --note-bg-yellow: rgba(255, 250, 229, 1);
  /*===============================
  Conplus Brand Colors
  ---------------------------------*/
  --white: rgb(255, 255, 255);
  --white-color-76: rgba(255, 255, 255, 0.76);
  --white-color-60: rgba(255, 255, 255, 0.6);
  --white-color-56: rgba(255, 255, 255, 0.56);
  --white-color-20: rgba(255, 255, 255, 0.2);
  --white-color-21: rgba(255, 255, 255, 0.21);
  --white-color-17: rgba(255, 255, 255, 0.17);
  --black-color: rgb(0, 0, 0);
  --black-color-85: rgba(0, 0, 0, 0.85);
  --black-color-80: rgba(0, 0, 0, 0.8);
  --black-color-45: rgba(0, 0, 0, 0.45);
  --black-color-40: rgba(0, 0, 0, 0.4);
  --black-color-36: rgba(0, 0, 0, 0.36);
  --black-color-30: rgba(0, 0, 0, 0.3);
  --black-color-24: rgba(0, 0, 0, 0.24);
  --black-color-21: rgba(0, 0, 0, 0.21);
  --black-color-20: rgba(0, 0, 0, 0.2);
  --black-color-16: rgba(0, 0, 0, 0.16);
  --black-color-15: rgba(0, 0, 0, 0.15);
  --black-color-12: rgba(0, 0, 0, 0.12);
  --black-color-10: rgba(0, 0, 0, 0.1);
  --black-color-07: rgba(0, 0, 0, 0.07);
  --black-color-06: rgba(0, 0, 0, 0.06);
  --black-color-05: rgba(0, 0, 0, 0.05);
  --black-color-03: rgba(0, 0, 0, 0.03);
  --black-color-54: rgba(0, 0, 0, 0.54);
}

.client-app {
  --primary-color: rgb(255, 119, 0);
  --primary-color-85: rgba(255, 119, 0, 0.85);
  --primary-color-70: rgba(255, 119, 0, 0.7);
  --primary-color-50: rgba(255, 119, 0, 0.5);
  --primary-color-40: rgba(255, 119, 0, 0.4);
  --primary-color-20: rgba(255, 119, 0, 0.2);
  --primary-color-16: rgba(255, 119, 0, 0.16);
  --primary-color-15: rgba(255, 119, 0, 0.15);
  --primary-color-12: rgba(255, 119, 0, 0.12);
  --primary-color-10: rgba(255, 119, 0, 0.1);
  --primary-color-06: rgba(255, 119, 0, 0.06);
  --primary-color-05: rgba(255, 119, 0, 0.05);
  --primary-color-03: rgba(255, 119, 0, 0.03);
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Button style overwrite here */
button.mat-button,
a.mat-button {
  color: #00abbb;
  transition: all 0.3s;
  border-radius: 6px;
  font-weight: 600;
  letter-spacing: 0.28px;
  outline: none;
  border: none;
  line-height: 20px;
  padding: 10px;
  font-family: "Open Sans", sans-serif;
}
button.mat-button.full-width,
a.mat-button.full-width {
  width: 100%;
}
button.mat-button.medium,
a.mat-button.medium {
  padding: 8px 10px;
}
button.mat-button.small,
a.mat-button.small {
  padding: 6px 10px;
}
button.mat-button.mat-primary,
a.mat-button.mat-primary {
  color: var(--primary-color);
}
button.mat-button.mat-primary:hover,
a.mat-button.mat-primary:hover {
  background-color: #FFF7F0;
}
button.mat-button.mat-secondary,
a.mat-button.mat-secondary {
  color: #00abbb;
}
button.mat-button.mat-secondary:hover,
a.mat-button.mat-secondary:hover {
  background-color: #ecf9fa;
}
button.mat-button.mat-calendar-period-button,
a.mat-button.mat-calendar-period-button {
  color: #0c121d;
}
button.mat-button[disabled],
a.mat-button[disabled] {
  color: #c2c4c6 !important;
  background-color: transparent !important;
  cursor: not-allowed;
}

button.mat-flat-button,
a.mat-flat-button {
  background-color: var(--white);
  border: none !important;
  color: var(--white);
  transition: all 0.3s;
  border-radius: 6px;
  padding: 10px;
  font-weight: 600;
  letter-spacing: 0.28px;
  outline: none;
  border: none;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
}
button.mat-flat-button.full-width,
a.mat-flat-button.full-width {
  width: 100%;
}
button.mat-flat-button.medium,
a.mat-flat-button.medium {
  padding: 8px 10px;
}
button.mat-flat-button.small,
a.mat-flat-button.small {
  padding: 6px 10px;
}
button.mat-flat-button.mat-primary,
a.mat-flat-button.mat-primary {
  background: var(--primary-color);
}
button.mat-flat-button.mat-primary:hover,
a.mat-flat-button.mat-primary:hover {
  background-color: #ff9233;
}
button.mat-flat-button.mat-secondary,
a.mat-flat-button.mat-secondary {
  background-color: #00abbb;
}
button.mat-flat-button.mat-secondary:hover,
a.mat-flat-button.mat-secondary:hover {
  background-color: #2ebbc9;
}
button.mat-flat-button.mat-accent,
a.mat-flat-button.mat-accent {
  background-color: #0c121d;
}
button.mat-flat-button.mat-accent:hover,
a.mat-flat-button.mat-accent:hover {
  background-color: #61656c;
}
button.mat-flat-button.mat-warn,
a.mat-flat-button.mat-warn {
  background-color: #e00034;
}
button.mat-flat-button.mat-warn:hover,
a.mat-flat-button.mat-warn:hover {
  background-color: #fa003a;
}
button.mat-flat-button[disabled],
a.mat-flat-button[disabled] {
  background-color: #c2c4c6 !important;
  color: var(--white) !important;
  cursor: not-allowed;
}

button.mat-stroked-button,
a.mat-stroked-button {
  border: 1px solid #0c121d;
  color: #0c121d;
  transition: all 0.3s;
  border-radius: 6px;
  padding: 9px 10px;
  font-weight: 600;
  letter-spacing: 0.28px;
  outline: none;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
}
button.mat-stroked-button.full-width,
a.mat-stroked-button.full-width {
  width: 100%;
}
button.mat-stroked-button.medium,
a.mat-stroked-button.medium {
  padding: 7px 10px;
}
button.mat-stroked-button.small,
a.mat-stroked-button.small {
  padding: 5px 10px;
}
button.mat-stroked-button.mat-primary,
a.mat-stroked-button.mat-primary {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
button.mat-stroked-button.mat-primary:hover,
a.mat-stroked-button.mat-primary:hover {
  background-color: #fff7f0;
}
button.mat-stroked-button.mat-secondary,
a.mat-stroked-button.mat-secondary {
  border: 1px solid #00abbb;
  color: #00abbb;
}
button.mat-stroked-button.mat-secondary:hover,
a.mat-stroked-button.mat-secondary:hover {
  background-color: #f0fafb;
}
button.mat-stroked-button.mat-accent,
a.mat-stroked-button.mat-accent {
  border: 1px solid #0c121d;
  color: #0c121d;
}
button.mat-stroked-button.mat-accent:hover,
a.mat-stroked-button.mat-accent:hover {
  background-color: #e7e7e8;
}
button.mat-stroked-button.mat-warn,
a.mat-stroked-button.mat-warn {
  border: 1px solid #E00034;
  color: #E00034;
}
button.mat-stroked-button.mat-warn:hover,
a.mat-stroked-button.mat-warn:hover {
  background-color: #FDF0F3;
}
button.mat-stroked-button[disabled],
a.mat-stroked-button[disabled] {
  background-color: #c2c4c6 !important;
  border-color: #c2c4c6 !important;
  color: var(--white) !important;
  cursor: not-allowed;
}

button.icon-button .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

button.mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #3d414a;
}

.button-section .text-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.delete-modal .text-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pdf-to-container .mat-icon-button,
.pdf-container .mat-icon-button {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pdf-to-container .mat-icon-button:hover,
.pdf-container .mat-icon-button:hover {
  background-color: #F2F7FA;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pdf-to-container .mat-icon-button .mat-button-wrapper,
.pdf-container .mat-icon-button .mat-button-wrapper {
  width: 20px;
  height: 20px;
  display: flex;
}
.pdf-to-container .mat-icon-button .mat-icon,
.pdf-container .mat-icon-button .mat-icon {
  color: #aaacb0;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Checkbox style overwrite here */
.mat-checkbox .mat-checkbox-frame {
  border-color: #aaacb0;
}
.mat-checkbox .mat-checkbox-label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #3d414a;
}
.mat-checkbox .mat-checkbox-layout {
  margin: 0;
}
.mat-checkbox.mat-secondary.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox.mat-secondary.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #00abbb;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #00abbb !important;
}

/* for tags*/
.tag {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: #3d414a;
  padding: 4px 8px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  min-width: 93px;
  display: inline-block;
  text-align: center;
  letter-spacing: normal;
  white-space: nowrap;
}
.tag.tag-void, .tag.tag-on-going, .tag.tag-inprogress, .tag.tag-pending {
  background-color: #fffae5;
  color: #e88b00;
}
.tag.tag-won, .tag.tag-accepted, .tag.tag-closed, .tag.tag-paid, .tag.tag-executed, .tag.tag-active, .tag.tag-approved, .tag.tag-completed, .tag.tag-pitch-invited {
  background-color: #f0faf6;
  color: #00af62;
}
.tag.tag-boarding-on-going, .tag.tag-open, .tag.tag-start, .tag.tag-started, .tag.tag-sow-invited, .tag.tag-invited, .tag.tag-not-yet-sent, .tag.tag-draft {
  background-color: #ecf9fa;
  color: #00abbb;
}
.tag.tag-uncollectible, .tag.tag-change-request, .tag.tag-inactive, .tag.tag-due, .tag.tag-requested, .tag.tag-rejected, .tag.tag-update-required, .tag.tag-changes-submitted, .tag.tag-stopped, .tag.tag-off-boarding {
  background-color: #fdf0f3;
  color: #e00034;
}

/* for breadcrumbs*/
.breadcrumbs li {
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  color: #85888e;
}
.breadcrumbs li:not(:last-child)::after {
  content: "/";
  padding: 0 6px;
}
.breadcrumbs li .route-link:hover {
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  color: #0AA1FB;
  text-decoration: underline;
}
.breadcrumbs li.more-actions {
  display: flex;
  align-items: center;
  padding: 4px 6px;
}
.breadcrumbs li.more-actions:hover, .breadcrumbs li.more-actions[aria-expanded=true] {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.05);
}
.breadcrumbs li:last-child {
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  color: #0c121d;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Chip Lists style overwrite here */
.mat-chip-list-wrapper .mat-chip {
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  color: #3d414a;
  background-color: #f6f6f6;
  border-radius: 6px;
  border: none;
  height: auto;
}
.mat-chip-list-wrapper .mat-chip .mat-chip-remove {
  width: 18px !important;
  height: 18px !important;
  color: #85888e;
  margin-left: 6px;
  opacity: 1;
}
.mat-chip-list-wrapper .mat-chip .mat-chip-remove:hover {
  opacity: 1 !important;
}
.mat-chip-list-wrapper .mat-chip::after {
  background: #e6ebee;
  border: none;
  transition: none;
}
.mat-chip-list-wrapper .mat-chip:hover::after {
  opacity: 1;
  z-index: -1;
}

.mat-chip-list-wrapper input.mat-input-element {
  background: none;
  border: 1px solid var(--primary-color-40) !important;
  color: var(--primary-color);
  white-space: normal;
  word-break: break-word;
  line-height: 1.3;
  outline: none !important;
  height: unset !important;
}

.table-chip-list .mat-chip-list-wrapper {
  flex-wrap: wrap !important;
}
.table-chip-list .mat-chip-list-wrapper .mat-chip {
  white-space: nowrap !important;
  word-break: keep-all !important;
}

.single-select-chip .mat-chip-list-wrapper {
  height: fit-content;
  overflow: auto;
}

.mat-chip-organize .mat-chip-list-wrapper .mat-standard-chip:hover {
  background: none;
}
.mat-chip-organize .mat-chip.mat-standard-chip .mat-chip-remove {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.mat-chip-organize .mat-chip.mat-standard-chip .mat-chip-remove:hover {
  background: rgba(255, 119, 0, 0.12);
}

.select-filter-chip {
  align-items: center;
}
.select-filter-chip .mat-chip-list-wrapper {
  margin: 0 !important;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* Material Date Picker style overwrite here */
.mat-form-field-suffix .mat-datepicker-toggle .mat-button-focus-overlay, .mat-form-field-suffix .mat-datepicker-toggle .mat-button-ripple {
  margin-left: 0;
}

.mat-datepicker-toggle-active {
  color: var(--text-grey-secondary);
}

.mat-calendar-body-selected {
  background-color: var(--text-grey-hint);
  color: var(--white);
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Dialog Modal style overwrite here */
.mat-dialog-container {
  border-radius: 10px !important;
}
.mat-dialog-container .mat-dialog-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  color: #0c121d;
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
.mat-dialog-container .close-icon {
  color: #0c121d;
  cursor: pointer;
}
.mat-dialog-container .dialog-info {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #61656c;
}
.mat-dialog-container .mat-dialog-actions {
  min-height: unset;
  margin-bottom: 0;
  padding: 24px 0 0 0;
}
.mat-dialog-container .mat-dialog-actions .mat-button-base + .mat-button-base {
  margin-left: 12px;
}

.support-contract-dialog .mat-dialog-container .mat-dialog-content {
  max-height: calc(100vh - 100px);
}

.view-action-dialog .mat-dialog-content {
  overflow: unset;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Mat form field style overwrite here */
mat-form-field.mat-form-field {
  font-size: 14px;
  margin-bottom: 16px;
  position: relative;
  font-family: "Open Sans", sans-serif;
}
mat-form-field.mat-form-field.full-width {
  width: 100%;
}
mat-form-field.mat-form-field .mat-form-field-label {
  font-size: 14px;
  line-height: 38px;
  font-weight: 500;
  color: #aaacb0;
}
mat-form-field.mat-form-field.mat-focused .mat-form-field-label {
  color: #aaacb0;
}
mat-form-field.mat-form-field .mat-form-field-flex {
  align-items: center;
}
mat-form-field.mat-form-field .mat-form-field-flex .mat-form-field-infix {
  width: 100%;
  border: 0;
  padding: 0;
}
mat-form-field.mat-form-field .mat-form-field-flex .mat-form-field-label {
  line-height: 38px;
  padding-left: 10px;
  top: 0.84375em;
}
mat-form-field.mat-form-field .mat-form-field-flex .mat-form-field-label .mat-placeholder-required {
  display: none;
}
mat-form-field.mat-form-field .mat-form-field-flex .mat-input-element {
  padding: 9px 10px;
  margin-top: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #0c121d;
}
mat-form-field.mat-form-field .mat-form-field-flex .mat-form-field-prefix .mat-icon,
mat-form-field.mat-form-field .mat-form-field-flex .mat-form-field-suffix .mat-icon {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #85888e;
  align-items: center;
  display: flex;
}
mat-form-field.mat-form-field .mat-form-field-flex .mat-form-field-prefix .mat-icon {
  margin-left: 10px;
}
mat-form-field.mat-form-field .mat-form-field-flex .mat-form-field-suffix .mat-icon {
  margin-right: 10px;
}
mat-form-field.mat-form-field .mat-form-field-flex .mat-form-field-prefix + .mat-form-field-infix {
  padding-left: 0;
}
mat-form-field.mat-form-field .mat-form-field-flex .mat-form-field-prefix + .mat-form-field-infix .mat-form-field-label {
  padding-left: 10px;
}
mat-form-field.mat-form-field.no-border-input {
  margin: 0;
}
mat-form-field.mat-form-field.no-border-input.options {
  margin-bottom: 10px;
}
mat-form-field.mat-form-field.no-border-input .mat-form-field-flex {
  border: none;
}
mat-form-field.mat-form-field.no-border-input .mat-form-field-flex .mat-input-element {
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #61656c;
}
mat-form-field.mat-form-field.medium .mat-form-field-flex .mat-form-field-label {
  line-height: 34px;
}
mat-form-field.mat-form-field.medium .mat-form-field-flex .mat-input-element {
  padding: 7px 10px;
}
mat-form-field.mat-form-field .mat-form-field-underline {
  bottom: 0;
  opacity: 0 !important;
  z-index: -1;
}
mat-form-field.mat-form-field .mat-floating-placeholder {
  opacity: 0 !important;
}
mat-form-field.mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0;
}
mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  border-color: #f2f7fa !important;
  background-color: #f2f7fa !important;
  border-radius: 6px;
}
mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-input-element {
  cursor: not-allowed;
}
mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-label {
  color: #aaacb0;
}
mat-form-field.mat-form-field-invalid .mat-form-field-flex {
  border: 1px solid #e00034 !important;
  background-color: #fdf0f3 !important;
}
mat-form-field.mat-form-field-invalid .mat-form-field-flex .mat-form-field-label {
  color: #aaacb0;
}
mat-form-field.mat-form-field textarea {
  box-shadow: none;
  min-height: 100px;
  max-height: 200px;
  resize: none;
  box-sizing: border-box;
  padding: 10px;
  margin: 0;
}

label.form-label {
  display: table;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #61656c;
  letter-spacing: normal;
  margin: 0 0 6px 0;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Mat form field Input text box style overwrite here */
mat-form-field.mat-form-field-type-mat-input input.mat-input-element {
  margin-top: 0;
  vertical-align: middle;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex {
  border: 1px solid #dbdbdd;
  border-radius: 6px;
  align-items: center;
  background: #fff;
}
mat-form-field.mat-form-field-type-mat-input.mat-focused .mat-form-field-flex {
  border: 1px solid #00abbb;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-subscript-wrapper {
  margin-top: 0;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-subscript-wrapper .mat-error {
  color: #e00034 !important;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  margin-top: 6px;
  overflow: visible;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-subscript-wrapper .mat-form-field-hint-wrapper {
  bottom: 0;
  padding-right: 2px;
}
mat-form-field.mat-form-field-type-mat-input.mat-form-field-invalid .mat-form-field-subscript-wrapper {
  position: relative;
}

/* Search and clear section style here */
.search-clear .mat-form-field-wrapper .mat-form-field-flex {
  border-bottom: 1px solid var(--grey-lighten-2) !important;
  padding: 8px 0 !important;
  border: none;
}
.search-clear .mat-form-field-wrapper .mat-form-field-prefix .search-icon i {
  font-size: 18px;
  line-height: 1;
  font-weight: normal;
  color: var(--text-grey-content);
}
.search-clear .mat-form-field-wrapper .mat-form-field-suffix .close-icon {
  font-size: 11px;
  line-height: 1;
  font-weight: 400;
  color: var(--text-grey-content);
  display: flex;
  align-items: center;
}
.search-clear .mat-form-field-wrapper .mat-form-field-suffix .close-icon i {
  font-size: 13px;
  background: var(--text-grey-content);
  border-radius: 50%;
  color: var(--white);
  margin-left: 16px;
  cursor: pointer;
}

.capabilities-input mat-form-field {
  margin-bottom: 0;
}
.capabilities-input mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex {
  border: none;
}
.capabilities-input mat-form-field.mat-form-field-type-mat-input.mat-form-field-invalid .mat-form-field-flex {
  background-color: transparent;
}

.send-message-container.mat-form-field-type-mat-input .mat-form-field-flex {
  border-radius: 50px !important;
  height: 52px;
  padding: 16px;
}

.company-info-input.mat-form-field-type-mat-input .mat-form-field-flex {
  border: none;
  flex-direction: row-reverse;
}
.company-info-input.mat-form-field-invalid .mat-form-field-flex {
  background-color: transparent !important;
}

.drawer-input-primary.mat-form-field .mat-form-field-wrapper {
  margin: 0;
  width: 200px;
}
.drawer-input-primary.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  top: 0;
}
.drawer-input-primary.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-start,
.drawer-input-primary.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-gap,
.drawer-input-primary.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-end {
  border-width: 1px;
}
.drawer-input-primary.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  width: unset;
}
.drawer-input-primary.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element {
  padding: 0;
  line-height: 40px;
}
.drawer-input-primary.mat-form-field:hover .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline, .drawer-input-primary.mat-form-field.mat-focused .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: var(--primary-color);
}

.impact-tracking-input mat-form-field.mat-form-field-type-mat-input input.mat-input-element {
  background: var(--white) !important;
  border: none;
}
.impact-tracking-input mat-form-field.mat-form-field-type-mat-input.mat-form-field-disabled .mat-form-field-flex {
  border-color: var(--white);
}
.impact-tracking-input mat-form-field.mat-form-field {
  margin: 0 !important;
}
.impact-tracking-input mat-form-field.mat-form-field-type-mat-select .mat-select:hover {
  box-shadow: none;
}

.dynamic-input .mat-form-field-type-mat-input input.mat-input-element {
  font-size: 16px;
  line-height: normal;
  height: 40px;
  padding: 10px 8px 4px 0;
}

.selection-table .mat-table .text-right .mat-form-field .mat-form-field-infix,
.rfp-selection-table .mat-table .text-right .mat-form-field .mat-form-field-infix {
  max-width: 104px;
  width: 100%;
  height: 36px;
}
.selection-table .mat-table .text-left .mat-form-field,
.rfp-selection-table .mat-table .text-left .mat-form-field {
  margin-bottom: 0 !important;
}
.selection-table .mat-table .text-left .mat-form-field .mat-form-field-infix,
.rfp-selection-table .mat-table .text-left .mat-form-field .mat-form-field-infix {
  height: 36px;
}

.template-editor-name mat-form-field {
  margin-bottom: 0 !important;
}
.template-editor-name mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex {
  border-radius: 6px;
}
.template-editor-name mat-form-field.mat-form-field-type-mat-input input.mat-input-element {
  height: 40px;
}
.template-editor-name mat-form-field.mat-form-field-type-mat-select .mat-select {
  border-radius: 6px;
}

.organization-input .dynamic-input .mat-form-field {
  margin-bottom: 0 !important;
}
.organization-input .dynamic-input .mat-form-field .mat-form-field-flex {
  border: none !important;
  border-bottom: 1px solid #dbdbdd;
  border-radius: 0 !important;
}
.organization-input .dynamic-input .mat-form-field .mat-form-field-flex .mat-form-field-infix .mat-input-element {
  padding: 10px 16px 4px 0 !important;
}
.organization-input .dynamic-input form {
  height: fit-content !important;
}
.organization-input .dynamic-input .mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Menu style overwrite here */
.mat-menu-panel {
  border-radius: 6px !important;
  border: 1px solid #f7fafc;
  background: #fff;
  box-shadow: 10px 0px 30px 0px rgba(0, 0, 0, 0.05), 1px 1px 5px 0px rgba(0, 0, 0, 0.05);
  padding: 4px;
  min-width: 140px !important;
  min-height: unset !important;
}
.mat-menu-panel .mat-menu-item {
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #61656c;
  padding: 8px 6px;
  height: 40px;
  display: flex;
  align-items: center;
}
.mat-menu-panel .mat-menu-item .mat-icon {
  color: #85888e;
  font-size: 20px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.mat-menu-panel .mat-menu-item.delete-action, .mat-menu-panel .mat-menu-item.stop-action {
  color: #e00034;
}
.mat-menu-panel .mat-menu-item.delete-action .mat-icon, .mat-menu-panel .mat-menu-item.stop-action .mat-icon {
  color: #e00034;
}
.mat-menu-panel .mat-menu-item.validate-action {
  color: #00af62;
}
.mat-menu-panel .mat-menu-item.validate-action .mat-icon {
  color: #00af62;
}
.mat-menu-panel .mat-menu-item:hover {
  background-color: #f7fafc;
}
.mat-menu-panel .mat-menu-content .mat-menu-item:focus {
  outline: none !important;
}

.notification-conatiner {
  width: 480px !important;
  max-width: 480px !important;
  padding: 0 !important;
}
.notification-conatiner .mat-menu-content .content-section {
  width: 100% !important;
  height: 366px !important;
}
.notification-conatiner .mat-menu-content .viewall-section {
  height: 50px;
}
.notification-conatiner .mat-menu-content .viewall-section .footer {
  font-size: 13px;
  line-height: normal;
  font-weight: 700;
  color: var(--blue-lighten4);
}
.notification-conatiner .request {
  width: 100% !important;
}

.emoji-menu {
  max-width: fit-content !important;
  padding: 0;
}

.mat-menu-panel.chip-more-vert {
  padding: 6px;
  border-radius: 4px;
  box-shadow: 0px 4px 12px 0px var(--black-opacity-10);
}
.mat-menu-panel.chip-more-vert .mat-menu-item {
  border-radius: 4px;
}
.mat-menu-panel.chip-more-vert .mat-menu-item:hover {
  background-color: #f0f0f0;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/** Mat pagination styles here */
.mat-paginator {
  background: transparent;
}
.mat-paginator-container {
  justify-content: flex-start !important;
  padding: 0 !important;
  min-height: unset !important;
}
.mat-paginator .mat-paginator-page-size {
  margin-right: 0;
}
.mat-paginator .mat-paginator-page-size-label {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: #61656c;
  margin: 0 6px;
}
.mat-paginator .mat-paginator-page-size-select {
  margin: 0;
}
.mat-paginator .mat-paginator-range-label {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: #61656c;
  margin: 0 12px;
}
.mat-paginator button:hover {
  background: #f2f7fa;
}
.mat-paginator .mat-icon-button {
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: #0c121d;
}
.mat-paginator .mat-icon-button .mat-paginator-icon {
  width: 20px;
}
.mat-paginator .mat-icon-button:disabled {
  color: #aaacb0;
  background: none;
}
.mat-paginator mat-form-field.mat-form-field-type-mat-select .mat-select {
  padding: 6px;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
mat-progress-bar.mat-progress-bar {
  border-radius: 3.5px;
  height: 8px;
}
mat-progress-bar.mat-progress-bar.progress-no-radius {
  border-radius: 0;
}
mat-progress-bar.mat-progress-bar .mat-progress-bar-fill::after {
  background-color: var(--green);
  border-radius: 10px;
}
mat-progress-bar.mat-progress-bar .mat-progress-bar-buffer {
  background-color: var(--grey-lighten-2);
}
mat-progress-bar.mat-progress-bar.mat-primary .mat-progress-bar-fill::after {
  background-color: var(--primary-color);
  border-radius: 10px;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Radio Button style overwrite here */
.mat-radio-button.mat-secondary .mat-radio-label {
  margin: 0;
}
.mat-radio-button.mat-secondary .mat-radio-outer-circle {
  border-color: #aaacb0;
}
.mat-radio-button.mat-secondary .mat-radio-label-content {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #3d414a;
}
.mat-radio-button.mat-secondary + .mat-radio-button {
  margin-left: 0;
}
.mat-radio-button.mat-secondary .mat-ripple-element {
  background-color: #00abbb !important;
}
.mat-radio-button.mat-secondary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #00abbb;
}
.mat-radio-button.mat-secondary.mat-radio-checked .mat-radio-inner-circle {
  background-color: #00abbb;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Mat form field Select box style overwrite here */
.mat-select-panel {
  border-radius: 6px !important;
  border: 1px solid #e7e7e8;
  background: #fff;
  box-shadow: 10px 0px 30px 0px rgba(0, 0, 0, 0.05), 1px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  padding: 8px !important;
}
.mat-select-panel .mat-option {
  color: #3d414a;
  font-size: 14px !important;
  padding: 8px;
  line-height: 36px !important;
  height: 36px !important;
  border-radius: 4px;
}
.mat-select-panel .mat-option:hover {
  background: #f7fafc;
}
.mat-select-panel .mat-option.mat-selected {
  background: #f2f7fa !important;
}
.mat-select-panel .mat-option.mat-selected .mat-option-text {
  font-weight: 600;
  color: #00abbb !important;
}

mat-form-field.mat-form-field-type-mat-select {
  position: relative;
}
mat-form-field.mat-form-field-type-mat-select .mat-select {
  background: #fff;
  border: 1px solid #dbdbdd;
  line-height: 20px;
  border-radius: 6px;
  padding: 9px 10px;
  width: 100%;
}
mat-form-field.mat-form-field-type-mat-select .mat-select .mat-select-arrow {
  border: none;
  margin: 0;
  width: 20px;
  height: 20px;
}
mat-form-field.mat-form-field-type-mat-select .mat-select .mat-select-arrow::after {
  font-family: "Material Icons";
  content: "";
  font-size: 24px;
  color: #85888e;
  position: relative;
  top: 0;
  right: 0;
  background: none;
}
mat-form-field.mat-form-field-type-mat-select .mat-select.mat-select-invalid .mat-select-arrow::after {
  color: #e00034;
}
mat-form-field.mat-form-field-type-mat-select .mat-select.action-status {
  box-shadow: 0 1px 2px 0 var(--black-color-24), 0 0 2px 0 var(--black-color-12);
}
mat-form-field.mat-form-field-type-mat-select .mat-select:hover {
  box-shadow: 0 1px 2px 0 var(--black-color-24), 0 0 2px 0 var(--black-color-12);
}
mat-form-field.mat-form-field-type-mat-select.medium .mat-select {
  padding: 7px 10px;
}
mat-form-field.mat-form-field-type-mat-select.mat-form-field-invalid .mat-form-field-subscript-wrapper {
  position: relative;
}
mat-form-field.mat-form-field-type-mat-select.mat-form-field-invalid .mat-form-field-label {
  color: var(--red) !important;
}
mat-form-field.mat-form-field-type-mat-select:focus .mat-select, mat-form-field.mat-form-field-type-mat-select.mat-focused .mat-select {
  border: 1px solid #00abbb;
}
mat-form-field.mat-form-field-type-mat-select:focus .mat-select.mat-select-invalid, mat-form-field.mat-form-field-type-mat-select.mat-focused .mat-select.mat-select-invalid {
  background: var(--white);
  border: 2px solid var(--red);
}
mat-form-field.mat-form-field-type-mat-select:focus .mat-select.mat-select-invalid .mat-select-arrow:after, mat-form-field.mat-form-field-type-mat-select.mat-focused .mat-select.mat-select-invalid .mat-select-arrow:after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  background-size: contain;
  top: 16px;
  right: -4px;
}
mat-form-field.mat-form-field-type-mat-select .mat-select-arrow {
  color: var(--text-primary);
}
mat-form-field.mat-form-field-type-mat-select .mat-form-field-label {
  color: var(--text-grey-content) !important;
}
mat-form-field.mat-form-field-type-mat-select.mat-focused.mat-primary .mat-select-arrow,
mat-form-field.mat-form-field-type-mat-select.mat-focused.mat-primary .mat-form-field-label {
  color: var(--text-primary);
}
mat-form-field.mat-form-field-type-mat-select.mat-form-field-disabled .mat-select {
  border-color: #f2f7fa;
  background-color: #f2f7fa;
}
mat-form-field.mat-form-field-type-mat-select.mat-form-field-disabled .mat-select .mat-select-arrow {
  border: none !important;
}
mat-form-field.mat-form-field-type-mat-select.mat-form-field-disabled .mat-select .mat-select-arrow:after {
  content: "";
  background: none;
  position: absolute;
  width: 16px;
  height: 16px;
  background-size: contain;
  top: 10px;
  right: -4px;
}
mat-form-field.mat-form-field-type-mat-select.mat-form-field-disabled .mat-select:hover {
  cursor: not-allowed;
}
mat-form-field.mat-form-field-type-mat-select.single-border .mat-select {
  border: none;
  border-bottom: 1px solid var(--grey-lighten-2);
  border-radius: 0;
}
mat-form-field.mat-form-field-type-mat-select.single-border .mat-select.mat-select-invalid {
  background: var(--red-lighten2);
  border: none;
  border-bottom: 1px solid var(--red);
}
mat-form-field.mat-form-field-type-mat-select.single-border:focus .mat-select.mat-select-invalid, mat-form-field.mat-form-field-type-mat-select.single-border.mat-focused .mat-select.mat-select-invalid {
  background: var(--white);
  border: none;
  border-bottom: 2px solid var(--red);
}
mat-form-field.mat-form-field-type-mat-select.transparent-form .mat-select {
  border: none;
  border-radius: 0;
}
mat-form-field.mat-form-field-type-mat-select.transparent-form .mat-select.mat-select-invalid {
  background: var(--red-lighten2);
  border: none;
}
mat-form-field.mat-form-field-type-mat-select.transparent-form:focus .mat-select.mat-select-invalid, mat-form-field.mat-form-field-type-mat-select.transparent-form.mat-focused .mat-select.mat-select-invalid {
  background: var(--white);
  border: none;
}
mat-form-field.mat-form-field-type-mat-select .mat-form-field-subscript-wrapper {
  margin-top: 0;
}
mat-form-field.mat-form-field-type-mat-select .mat-form-field-subscript-wrapper .mat-error {
  color: #e00034 !important;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  margin-top: 6px;
  overflow: visible;
}
mat-form-field.mat-form-field-type-mat-select .mat-form-field-subscript-wrapper .mat-error.error-left {
  top: 3px;
  left: calc(-100% - 12px);
}
mat-form-field.mat-form-field-type-mat-select .mat-form-field-subscript-wrapper .mat-error.error-left::after {
  bottom: unset;
  right: -12px;
  transform: rotateZ(-90deg);
  top: 12px;
}
mat-form-field.mat-form-field-type-mat-select .mat-form-field-subscript-wrapper .mat-form-field-hint-wrapper {
  bottom: 0;
  padding-right: 2px;
}
mat-form-field.mat-form-field-type-mat-select:hover .mat-form-field-subscript-wrapper .mat-error:nth-of-type(1), mat-form-field.mat-form-field-type-mat-select:focus .mat-form-field-subscript-wrapper .mat-error:nth-of-type(1), mat-form-field.mat-form-field-type-mat-select.mat-focused .mat-form-field-subscript-wrapper .mat-error:nth-of-type(1) {
  display: block;
}
mat-form-field.mat-form-field-type-mat-select:focus .mat-form-field-flex, mat-form-field.mat-form-field-type-mat-select.mat-focused .mat-form-field-flex {
  border-color: var(--primary-color);
}
mat-form-field.mat-form-field-type-mat-select.mat-form-field-invalid .mat-form-field-flex {
  background-color: var(--red-lighten2);
  border-color: var(--red);
  border-width: 1px;
  border-style: solid;
  align-items: center;
  border-radius: 6px;
}
mat-form-field.mat-form-field-type-mat-select.mat-form-field-invalid .mat-form-field-flex:hover {
  box-shadow: 0 1px 2px 0 var(--black-color-24), 0 0 2px 0 var(--black-color-12);
}
mat-form-field.mat-form-field-type-mat-select.mat-form-field-invalid .mat-form-field-flex .mat-select {
  background-color: #fdf0f3 !important;
  border: none;
}
mat-form-field.mat-form-field-type-mat-select.mat-form-field-invalid .mat-form-field-flex .mat-select:hover {
  box-shadow: none;
}

.single-level-select-container .mat-form-field .mat-form-field-flex {
  padding-top: 6px !important;
  padding-bottom: 4px !important;
}
.single-level-select-container .mat-form-field.mat-form-field-type-mat-select .mat-form-field-outline {
  display: none;
}
.single-level-select-container .mat-form-field.mat-form-field-type-mat-select .mat-select-arrow:after {
  top: 24px !important;
}

.selection-dropdown {
  position: absolute;
  top: 44px;
  right: -54px;
}

.selection-dropdown-single {
  position: absolute;
  top: 44px;
  right: -30px;
}

.impact-tracking-input mat-form-field {
  max-width: 120px;
  width: 100%;
}
.impact-tracking-input mat-form-field.mat-form-field-type-mat-select.mat-form-field-disabled .mat-select {
  background-color: var(--white);
}
.impact-tracking-input mat-form-field.mat-form-field-type-mat-select .mat-select {
  height: 42px;
}
.impact-tracking-input .mat-select-value-text {
  color: var(--black-color);
  font-weight: 600;
}

.template-font-family .mat-form-field {
  border-radius: 6px;
  background: var(--grey-lighten-5);
}
.template-font-family .mat-form-field .mat-form-field-flex {
  min-width: 104px;
}
.template-font-family .mat-form-field.mat-form-field-type-mat-select .mat-select {
  border-radius: 6px;
}

.template-font-size .mat-form-field {
  border-radius: 6px;
  background: var(--grey-lighten-5);
}
.template-font-size .mat-form-field .mat-form-field-flex {
  min-width: 74px;
}
.template-font-size .mat-form-field.mat-form-field-type-mat-select .mat-select {
  border-radius: 6px;
}

.template-font-style .mat-form-field {
  border-radius: 6px;
  background: var(--grey-lighten-5);
}
.template-font-style .mat-form-field .mat-form-field-flex {
  min-width: 74px;
}
.template-font-style .mat-form-field.mat-form-field-type-mat-select .mat-select {
  border-radius: 6px;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Slide Toggle style overwrite here */
.mat-slide-toggle.mat-secondary .mat-slide-toggle-bar {
  border-radius: 50px;
  border: 1px solid #e7e7e8;
  background: #f7fafc;
  width: 36px;
  height: 20px;
}
.mat-slide-toggle.mat-secondary .mat-slide-toggle-bar .mat-slide-toggle-thumb-container {
  width: 13px;
  height: 13px;
  top: 2.5px;
  left: 3px;
}
.mat-slide-toggle.mat-secondary .mat-slide-toggle-bar .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
  height: 13px;
  width: 13px;
  box-shadow: none;
  border-radius: 50%;
  background-color: #aaacb0;
}
.mat-slide-toggle.mat-secondary .mat-slide-toggle-bar .mat-slide-toggle-ripple {
  visibility: hidden;
}
.mat-slide-toggle.mat-secondary .mat-slide-toggle-content {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #3d414a;
}
.mat-slide-toggle.mat-secondary.mat-checked .mat-slide-toggle-bar {
  border: 1px solid #cdf5f8;
  background: #ecf9fa;
}
.mat-slide-toggle.mat-secondary.mat-checked .mat-slide-toggle-thumb {
  background-color: #00abbb !important;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
  top: 18px;
}
.mat-slider.mat-slider-horizontal .mat-slider-wrapper:hover {
  background: var(--grey-lighten-3) !important;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 12px;
  border-radius: 10px;
  background: var(--grey-lighten-3);
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  background-color: var(--dark-green);
}

.mat-accent .mat-slider-thumb {
  height: 30px;
  width: 30px;
  background-color: var(--white);
  border: solid 2px var(--grey-lighten-6);
  bottom: -20px;
  right: -20px;
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: var(--white);
}

.mat-slider-thumb-label {
  background: var(--dark-green) !important;
}

.mat-slider-disabled .mat-slider-thumb {
  border-width: 4px;
  transform: scale(0.7);
}

/* score card NPS slider style here */
.score-card-nps .mat-slider {
  width: 96%;
}
.score-card-nps .mat-slider .mat-slider-wrapper .mat-slider-track-wrapper {
  height: 6px !important;
}
.score-card-nps .mat-slider .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb {
  transform: rotate(45deg) !important;
  border-radius: 50% !important;
  width: 15px;
  height: 15px;
  bottom: -10px;
  right: -8px;
  background-color: var(--dark-green);
  border-color: var(--dark-green);
  box-shadow: 0 2px 4px var(--black-color-20);
}
.score-card-nps .mat-slider .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb .mat-slider-thumb-label {
  display: none;
}
.score-card-nps .mat-slider .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb-label {
  display: none;
}

.question-options .mat-slider-wrapper .mat-slider-track-wrapper {
  height: 8px !important;
}
.question-options .mat-slider-wrapper .mat-slider-track-wrapper .mat-slider-track-background {
  background-color: var(--black-color-10);
  border: 1px solid var(--black-color-05);
}
.question-options .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb-label {
  top: -12px !important;
  width: 30px;
  height: 30px;
  border-radius: 20% !important;
  background: var(--white) !important;
  border: 1px solid var(--dark-green) !important;
}
.question-options .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb-label .mat-slider-thumb-label-text {
  color: var(--dark-green) !important;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Mat Snackbar style overwrite here */
.mat-snack-bar-container {
  box-shadow: 0px 13px 16px 0px rgba(0, 0, 0, 0.1);
  border-left: 5px solid;
  padding: 12px 16px !important;
  min-width: 405px !important;
  background: #fff;
  border-radius: 8px !important;
}
.mat-snack-bar-container .info-icon {
  display: inline-table;
  padding: 6px;
}
.mat-snack-bar-container .close-icon {
  color: #85888E;
}
.mat-snack-bar-container p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #0C121D;
  letter-spacing: -0.408px;
}
.mat-snack-bar-container.success-snack-bar {
  border-color: #00AF62;
}
.mat-snack-bar-container.success-snack-bar .info-icon {
  color: #00AF62;
  background-color: #F0FAF6;
}
.mat-snack-bar-container.error-snack-bar {
  border-color: #E00034;
}
.mat-snack-bar-container.error-snack-bar .info-icon {
  color: #E00034;
  background-color: #FDF0F3;
}
.mat-snack-bar-container.warning-snack-bar {
  border-color: #ffcc00;
}
.mat-snack-bar-container.warning-snack-bar .info-icon {
  color: #ffcc00;
  background-color: rgba(255, 204, 0, 0.2);
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* Material Loader style overwrite here */
.mat-progress-spinner.mat-primary circle,
.mat-spinner.mat-primary circle {
  stroke: var(--primary-color);
}

.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
  stroke: var(--green);
}

.mat-progress-spinner.mat-warn circle,
.mat-spinner.mat-warn circle {
  stroke: var(--warn-color);
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* Mat form field Textarea style overwrite here */
mat-form-field.mat-form-field-type-mat-input.textarea .mat-form-field-infix {
  padding: 0;
}
mat-form-field.mat-form-field-type-mat-input.textarea .mat-form-field-infix textarea {
  box-shadow: none;
  min-height: 60px;
  line-height: 1.4;
  padding: 0 8px;
  margin: 8px 0;
  resize: none;
}
mat-form-field.mat-form-field-type-mat-input.textarea .mat-form-field-infix textarea:focus {
  border: none;
}

.performance-textarea .mat-input-element {
  resize: none !important;
  height: 80px;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

/* Material Tooltip style overwrite here */
.mat-tooltip {
  font-size: 12px;
  background: var(--text-grey-secondary);
  position: relative;
}
.mat-tooltip::after {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid var(--text-grey-secondary);
}
.mat-tooltip.below {
  overflow: initial;
  margin-top: 1rem;
}
.mat-tooltip.below:after {
  top: -0.5rem;
  right: calc(50% - 0.5rem);
  transform: rotate(0);
}
.mat-tooltip.above {
  overflow: initial;
  margin-bottom: 1rem;
}
.mat-tooltip.above:after {
  bottom: -0.5rem;
  right: calc(50% - 0.5rem);
  transform: rotate(180deg);
}
.mat-tooltip.right {
  overflow: initial;
  margin-left: 1rem;
}
.mat-tooltip.right:after {
  left: -0.75rem;
  top: calc(50% - 0.25rem);
  transform: rotate(270deg);
}
.mat-tooltip.left {
  overflow: initial;
  margin-right: 1rem;
}
.mat-tooltip.left:after {
  right: -0.75rem;
  top: calc(50% - 0.25rem);
  transform: rotate(90deg);
}

.example-tooltip-black {
  background: var(--text-primary);
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* Material Button-toggle style overwrite here */
.dashboard-button-toggle .mat-button-toggle-group {
  border-radius: 50px;
}
.dashboard-button-toggle .mat-button-toggle-group .mat-button-toggle .mat-button-toggle-label-content {
  line-height: 32px !important;
  font-size: 14px;
  padding: 0 20px;
}
.dashboard-button-toggle .mat-button-toggle-group .mat-button-toggle-checked {
  background-color: var(--primary-color);
  color: var(--white);
}

.project-changes-custom-dialog .mat-dialog-container {
  max-height: 600px;
  overflow: hidden !important;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: #ff7700;
}
.mat-tab-group .mat-tab-header {
  border-bottom: 1px solid #dbdbdd;
}
.mat-tab-group .mat-tab-header .mat-tab-list {
  margin: 0 10px;
}
.mat-tab-group .mat-tab-label {
  height: 40px;
  padding: 0 8px;
  opacity: 1;
  min-width: unset;
}
.mat-tab-group .mat-tab-label .mat-tab-label-content {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: #85888e;
}
.mat-tab-group .mat-tab-label-active .mat-tab-label-content {
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  color: #ff7700;
}

.project-overflow .mat-tab-group {
  height: calc(100vh - 310px);
}
.project-overflow .mat-tab-group .mat-tab-body-content {
  overflow: inherit;
}
.project-overflow.inside-provider .mat-tab-group {
  height: calc(100vh - 329px);
}

.dashboard-box .view-sourcing-invitation-section-content .mat-tab-body {
  padding: 20px 20px 12px;
  width: calc(100vw - 370px);
  height: calc(100vh - 258px);
}
.dashboard-box .view-sourcing-invitation-section-content .mat-tab-body:first-child {
  padding: 0;
}
.dashboard-box .view-sourcing-invitation-section-content .mat-tab-body:first-child .mat-tab-body-content {
  height: calc(100vh - 370px);
  overflow: auto;
  padding: 20px 20px 12px !important;
}

.dashboard-box .view-projects-tab .mat-tab-body .mat-tab-body-content {
  height: calc(100vh - 306px);
  overflow: auto;
  padding: 20px !important;
}

.demand-other-tab .dashboard-box .view-projects-tab .mat-tab-body,
.sourcing-invitation-container .dashboard-box .view-projects-tab .mat-tab-body {
  padding: 0 !important;
  overflow-x: hidden;
}

.demand-other-tab .dashboard-box .view-projects-tab .mat-tab-body,
.sourcing-invitation-container .dashboard-box .view-projects-tab .mat-tab-body {
  padding: 0 !important;
}

.dashboard-box .view-project-tab.project-mangement-invoicing .mat-tab-body {
  height: 100% !important;
}

.view-sourcing-invitation-section-content.sow-layout-step .mat-tab-body-content {
  height: calc(100vh - 340px);
}

.dashboard-box.sow-layout-step .sow-tab-group .mat-tab-body {
  padding: 0;
}
.dashboard-box.sow-layout-step .sow-tab-group .mat-tab-body-content {
  height: calc(100vh - 233px);
}

.terms-condition-group .sow-container {
  height: calc(100vh - 366px);
  overflow: auto;
}

.dashboard-box.view-rfp-section-content {
  height: calc(100vh - 192px) !important;
}
.dashboard-box.view-rfp-section-content .mat-tab-body:nth-child(10) {
  padding: 0 !important;
}

.performance-tab-group .mat-tab-body {
  padding: 0;
}
.performance-tab-group .mat-tab-body-content {
  height: calc(100vh - 233px) !important;
}

.view-projects-tab .mat-tab-body {
  overflow: auto;
  height: calc(100vh - 306px);
}

.view-nda-tab .mat-tab-body {
  overflow-y: auto !important;
  height: calc(100vh - 230px);
}
.view-nda-tab .mat-tab-body:first-child .mat-tab-body-content {
  height: calc(100vh - 418px);
  overflow-x: inherit;
}

.nda-container .view-sourcing-invitation-section-content .mat-tab-body-content {
  height: calc(100vh - 304px);
}

.view-msa-tab .mat-tab-body {
  padding: 20px 0;
  overflow-y: auto !important;
  height: calc(100vh - 258px);
}
.view-msa-tab .mat-tab-body:first-child .mat-tab-body-content {
  height: calc(100vh - 418px);
  overflow-x: inherit;
}

.sow-projects-tab .mat-tab-body {
  padding: 20px 0;
  overflow-y: auto !important;
  height: calc(100vh - 434px);
}
.sow-projects-tab .mat-tab-body:first-child .mat-tab-body-content {
  height: 100%;
  width: 100vw;
  overflow-x: hidden !important;
}

.sow-settings-tab .mat-tab-body {
  padding: 20px 0;
  overflow-y: auto !important;
  height: calc(100vh - 300px);
}
.sow-settings-tab .mat-tab-body:first-child .mat-tab-body-content {
  height: 100%;
}

.conpulse-tab-group .mat-tab-group .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
  max-width: fit-content !important;
  min-width: fit-content;
  height: 40px;
  padding: 11px 8px !important;
  margin: 0 !important;
}

.rfp-tab-group .mat-tab-group .mat-tab-body {
  overflow-x: hidden;
  height: calc(100vh - 306px) !important;
}
.rfp-tab-group .mat-tab-group .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
  max-width: 100% !important;
  min-width: fit-content !important;
  padding: 11px 8px !important;
  margin: 0 !important;
}
.rfp-tab-group .mat-tab-group .mat-ink-bar {
  max-width: 100% !important;
}

.impact-tab-group .mat-tab-group .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
  max-width: fit-content !important;
  min-width: fit-content;
  padding: 11px 8px;
  height: 40px;
}
.impact-tab-group .mat-tab-group .mat-ink-bar {
  max-width: 100% !important;
}

.tab-group.mat-tab-group .mat-tab-header .mat-tab-label {
  min-width: unset;
  height: 40px !important;
}
.tab-group.mat-tab-group .mat-tab-header .mat-tab-label.mat-tab-label-active {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: var(--primary-color) !important;
  opacity: 1 !important;
}
.tab-group.mat-tab-group .mat-tab-header .mat-ink-bar {
  background-color: var(--primary-color) !important;
}
.tab-group.mat-tab-group .mat-tab-body {
  padding: 16px 0;
}

.nav-tab-group .mat-tab-link-container {
  margin: 0 10px;
}
.nav-tab-group .mat-tab-link {
  min-width: unset;
  height: 40px !important;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: #85888e;
  opacity: 1;
  padding: 11px 8px;
}
.nav-tab-group .mat-tab-link:hover {
  color: #85888e;
  text-decoration: none;
}
.nav-tab-group .mat-tab-link.mat-tab-label-active {
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  color: #ff7700;
  opacity: 1 !important;
}
.nav-tab-group .mat-ink-bar {
  background-color: var(--primary-color) !important;
}

.view-sourcing-invitation-section-content .mat-tab-body-content {
  height: calc(100vh - 314px);
}

.view-legal-entity-tab .mat-tab-body-content {
  padding: 0;
  overflow: auto;
  height: calc(100vh - 181px);
}

.view-client-profile-tab .mat-tab-group .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
  max-width: 160px !important;
  min-width: 160px !important;
  height: 40px;
}
.view-client-profile-tab .mat-tab-body {
  padding: 20px 0;
  overflow-y: auto !important;
  height: calc(100vh - 360px);
}
.view-client-profile-tab .mat-tab-body:first-child .mat-tab-body-content {
  height: 100%;
}

.entityscreen {
  padding: 0 !important;
}
.entityscreen .mat-drawer-container .mat-drawer-content {
  padding: 0 !important;
}
.entityscreen .view-legal-entity-tab .mat-tab-body-content {
  height: calc(100vh - 291px) !important;
}

.library-management-tab .mat-tab-body {
  padding: 0 !important;
}

.selection-criteria-group .mat-tab-body {
  padding: 20px;
  height: calc(100vh - 306px);
}

.question-answer-container .view-question-answer-section-content {
  height: calc(100vh - 210px) !important;
  overflow: auto !important;
}

.sourcing-invitation-container .view-sourcing-invitation-section-content .associated-group .reference-document .sow-document {
  height: calc(100vh - 378px) !important;
}
.sourcing-invitation-container .view-sourcing-invitation-section-content .associated-group .mat-tab-body .mat-tab-body-content {
  height: calc(100vh - 233px) !important;
}

.project-management-details-tab .mat-tab-body {
  padding: 0;
}
.project-management-details-tab .mat-tab-body-content {
  height: calc(100vh - 244px) !important;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

.table-with-white-bg .mat-table,
.table-with-grey-bg .mat-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #e7e7e8;
  border-radius: 8px;
  overflow: hidden;
}
.table-with-white-bg .mat-table tr th,
.table-with-grey-bg .mat-table tr th {
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  color: #0c121d;
  text-transform: uppercase;
  background-color: #fff;
}
.table-with-white-bg .mat-table tr td,
.table-with-grey-bg .mat-table tr td {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: #61656c;
}
.table-with-white-bg .mat-table tr.mat-header-row,
.table-with-grey-bg .mat-table tr.mat-header-row {
  height: 40px;
}
.table-with-white-bg .mat-table tr.mat-header-row .mat-header-cell,
.table-with-grey-bg .mat-table tr.mat-header-row .mat-header-cell {
  border-bottom: 1px solid #e7e7e8;
}
.table-with-white-bg .mat-table tr.mat-row,
.table-with-grey-bg .mat-table tr.mat-row {
  height: 48px !important;
  background-color: #fff;
}
.table-with-white-bg .mat-table tr.mat-row .mat-cell,
.table-with-grey-bg .mat-table tr.mat-row .mat-cell {
  border-bottom-color: #e7e7e8;
}
.table-with-white-bg .mat-table tr.mat-row:hover, .table-with-white-bg .mat-table tr.mat-row.active,
.table-with-grey-bg .mat-table tr.mat-row:hover,
.table-with-grey-bg .mat-table tr.mat-row.active {
  background-color: #f7fafc;
}
.table-with-white-bg .mat-table tr.mat-row:last-child .mat-cell,
.table-with-grey-bg .mat-table tr.mat-row:last-child .mat-cell {
  border-bottom: none;
}
.table-with-white-bg .mat-table th.mat-header-cell:first-of-type,
.table-with-white-bg .mat-table td.mat-cell:first-of-type,
.table-with-grey-bg .mat-table th.mat-header-cell:first-of-type,
.table-with-grey-bg .mat-table td.mat-cell:first-of-type {
  padding-left: 12px;
}
.table-with-white-bg .mat-table th.mat-header-cell:last-of-type,
.table-with-white-bg .mat-table td.mat-cell:last-of-type,
.table-with-grey-bg .mat-table th.mat-header-cell:last-of-type,
.table-with-grey-bg .mat-table td.mat-cell:last-of-type {
  padding: 8px 12px;
}

.table-with-grey-bg .mat-table tr th {
  background-color: #f2f7fa;
}

.table-data {
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  color: #0c121d;
}

/* Table selection box styles here */
.table-action {
  padding: 6px 12px;
  background-color: #fff;
  border-radius: 5px;
}
.table-action .count {
  width: 30px;
  height: 30px;
  font-size: 13px;
  line-height: 30px;
  font-weight: 700;
  color: #0c121d;
  border-radius: 6px;
  background: #f6f6f6;
}
.table-action .page-title1 {
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  color: #000000;
}
.table-action .info {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #0c121d;
}
.table-action-grey {
  background-color: #f2f6f9;
}
.table-action-grey .count {
  background: #fff;
}

/* Strategic priorities table styles here */
.strategic-priorities-table-responsive {
  height: calc(100vh - 202px) !important;
}
.strategic-priorities-table-responsive tr th:nth-child(1),
.strategic-priorities-table-responsive tr td:nth-child(1) {
  width: 60px;
}
.strategic-priorities-table-responsive tr th:nth-child(2),
.strategic-priorities-table-responsive tr td:nth-child(2) {
  min-width: 100px;
  max-width: 100px;
}
.strategic-priorities-table-responsive tr th:nth-child(3), .strategic-priorities-table-responsive tr th:nth-child(4), .strategic-priorities-table-responsive tr th:nth-child(5), .strategic-priorities-table-responsive tr th:nth-child(6), .strategic-priorities-table-responsive tr th:nth-child(8),
.strategic-priorities-table-responsive tr td:nth-child(3),
.strategic-priorities-table-responsive tr td:nth-child(4),
.strategic-priorities-table-responsive tr td:nth-child(5),
.strategic-priorities-table-responsive tr td:nth-child(6),
.strategic-priorities-table-responsive tr td:nth-child(8) {
  min-width: 150px;
  max-width: 150px;
}
.strategic-priorities-table-responsive tr th:last-child,
.strategic-priorities-table-responsive tr td:last-child {
  width: 100px;
  text-align: center;
  padding: 9px 12px;
}
.strategic-priorities-table-responsive tr th .more-actions,
.strategic-priorities-table-responsive tr th .view-action,
.strategic-priorities-table-responsive tr td .more-actions,
.strategic-priorities-table-responsive tr td .view-action {
  color: #7f7f7f;
  display: none;
}
.strategic-priorities-table-responsive tr th .more-actions:hover,
.strategic-priorities-table-responsive tr th .view-action:hover,
.strategic-priorities-table-responsive tr td .more-actions:hover,
.strategic-priorities-table-responsive tr td .view-action:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.strategic-priorities-table-responsive tr:hover .more-actions,
.strategic-priorities-table-responsive tr:hover .view-action {
  display: inline-table;
}
.strategic-priorities-table-responsive tr .more-actions[aria-expanded=true] {
  display: inline-table;
  background-color: rgba(0, 0, 0, 0.05);
}
.strategic-priorities-table-responsive.table-select-item {
  height: calc(100vh - 256px) !important;
}

.strategic-priorities-table-responsive-no-select .mat-table .mat-header-row .mat-header-cell:nth-child(1) {
  min-width: 100px;
}
.strategic-priorities-table-responsive-no-select .mat-table .mat-header-row .mat-header-cell:nth-child(2) {
  min-width: 200px;
}
.strategic-priorities-table-responsive-no-select .mat-table .mat-header-row .mat-header-cell:nth-child(3) {
  min-width: 400px;
}
.strategic-priorities-table-responsive-no-select .mat-table .mat-header-row .mat-header-cell:nth-child(4) {
  min-width: 200px;
}
.strategic-priorities-table-responsive-no-select .mat-table .mat-header-row .mat-header-cell:nth-child(5) {
  min-width: 200px;
}
.strategic-priorities-table-responsive-no-select .mat-table .mat-header-row .mat-header-cell:nth-child(6) {
  min-width: 100px !important;
}
.strategic-priorities-table-responsive-no-select .mat-table .mat-header-row .mat-header-cell:last-of-type {
  text-align: center;
}
.strategic-priorities-table-responsive-no-select table tr.mat-row td.mat-cell:last-of-type div div {
  display: none !important;
  font-size: 13px;
  color: #7f7f7f !important;
  width: 28px;
  height: 28px;
  border-radius: 50px;
  line-height: 0;
}
.strategic-priorities-table-responsive-no-select table tr.mat-row td.mat-cell:last-of-type div div:hover {
  background: #e3e3e3;
}
.strategic-priorities-table-responsive-no-select table tr:hover {
  background-color: #f6f6f6 !important;
}
.strategic-priorities-table-responsive-no-select table tr:hover.mat-row td.mat-cell:last-of-type div div {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

/* Legal entities table styles here */
.legal-entity-table {
  height: calc(100vh - 330px) !important;
}
.legal-entity-table tr th:nth-child(1), .legal-entity-table tr th:nth-child(2), .legal-entity-table tr th:nth-child(3),
.legal-entity-table tr td:nth-child(1),
.legal-entity-table tr td:nth-child(2),
.legal-entity-table tr td:nth-child(3) {
  min-width: 150px;
  max-width: 150px;
}
.legal-entity-table tr th:nth-child(4),
.legal-entity-table tr td:nth-child(4) {
  min-width: 130px;
  max-width: 130px;
}
.legal-entity-table tr th:nth-child(7), .legal-entity-table tr th:nth-child(9),
.legal-entity-table tr td:nth-child(7),
.legal-entity-table tr td:nth-child(9) {
  min-width: 120px;
  max-width: 120px;
}
.legal-entity-table tr th:nth-child(5), .legal-entity-table tr th:nth-child(6), .legal-entity-table tr th:nth-child(8),
.legal-entity-table tr td:nth-child(5),
.legal-entity-table tr td:nth-child(6),
.legal-entity-table tr td:nth-child(8) {
  min-width: 100px;
  max-width: 100px;
}
.legal-entity-table tr th:nth-child(10),
.legal-entity-table tr td:nth-child(10) {
  width: 100px;
}
.legal-entity-table tr th:last-child,
.legal-entity-table tr td:last-child {
  text-align: center;
}
.legal-entity-table tr th .more-actions,
.legal-entity-table tr td .more-actions {
  color: #7f7f7f;
  display: none;
}
.legal-entity-table tr th .more-actions:hover,
.legal-entity-table tr td .more-actions:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.legal-entity-table tr:hover .more-actions {
  display: inline-table;
}
.legal-entity-table tr .more-actions[aria-expanded=true] {
  display: inline-table;
  background-color: rgba(0, 0, 0, 0.05);
}

.demand-other-tab .dashboard-box .view-projects-tab .mat-tab-body .table-section {
  height: calc(100vh - 464px);
}

.entityscreen .admin-entity-billing-table-responsive {
  height: calc(100vh - 444px) !important;
}

.entityscreen .admin-entity-billing-table-responsive {
  height: calc(100vh - 444px) !important;
}

.reference-document .table .sow-document {
  height: calc(100vh - 500px) !important;
}

.project-manage-invocing-tab .table-section {
  height: calc(100vh - 338px) !important;
}

.selection-group .result-table .mat-table tr th:first-child,
.selection-group .result-table .mat-table tr td:first-child {
  width: 80px;
  padding: 9px 12px;
}
.selection-group .result-table .mat-table tr th:nth-child(2), .selection-group .result-table .mat-table tr th:nth-child(5), .selection-group .result-table .mat-table tr th:nth-child(7),
.selection-group .result-table .mat-table tr td:nth-child(2),
.selection-group .result-table .mat-table tr td:nth-child(5),
.selection-group .result-table .mat-table tr td:nth-child(7) {
  min-width: 150px;
  max-width: 150px;
}
.selection-group .result-table .mat-table tr th:nth-child(3), .selection-group .result-table .mat-table tr th:nth-child(4),
.selection-group .result-table .mat-table tr td:nth-child(3),
.selection-group .result-table .mat-table tr td:nth-child(4) {
  min-width: 80px;
  max-width: 80px;
}
.selection-group .result-table .mat-table tr th:nth-child(6),
.selection-group .result-table .mat-table tr td:nth-child(6) {
  min-width: 100px;
  max-width: 100px;
}
.selection-group .result-table .mat-table tr th:nth-child(8),
.selection-group .result-table .mat-table tr td:nth-child(8) {
  min-width: 120px;
  max-width: 120px;
}
.selection-group .result-table .mat-table tr th:last-child,
.selection-group .result-table .mat-table tr td:last-child {
  width: 100px;
  padding: 9px 12px;
  text-align: center;
}
.selection-group .result-table .mat-table .selection-drag {
  color: #7f7f7f;
  display: none;
}
.selection-group .result-table .mat-table .more-actions {
  color: #7f7f7f;
  display: none;
}
.selection-group .result-table .mat-table .more-actions:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.selection-group .result-table:hover .selection-drag {
  display: inline-table !important;
}
.selection-group .result-table:hover .more-actions {
  display: inline-table !important;
}
.selection-group .result-table .more-actions[aria-expanded=true] {
  display: inline-table !important;
  background-color: rgba(0, 0, 0, 0.05);
}

.currency-header-sticky {
  height: calc(100vh - 356px) !important;
}
.currency-header-sticky .currency-conversion {
  height: auto !important;
  max-height: calc(100vh - 406px);
  border: 1px solid var(--border-light-grey);
  border-radius: 8px;
}
.currency-header-sticky .mat-table {
  border: none;
  overflow: auto !important;
}
.currency-header-sticky .mat-table tr:last-of-type {
  border-bottom: none;
}
.currency-header-sticky .mat-table tr:last-of-type td {
  border-bottom: none;
}

.impact-tracing-table table th {
  background-color: var(--blue-lighten6) !important;
}

.impact-tracing-table table .mat-header-row {
  height: 40px;
  background-color: var(--blue-lighten6) !important;
}
.impact-tracing-table table .mat-header-row .mat-header-cell {
  padding: 12px;
  color: #424242 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}
.impact-tracing-table table .mat-header-row .mat-header-cell:first-of-type {
  padding-left: 16px !important;
}
.impact-tracing-table table .mat-header-row .mat-header-cell:last-of-type {
  padding-right: 16px !important;
}
.impact-tracing-table table .mat-cell {
  padding: 12px;
}
.impact-tracing-table table .mat-cell:first-of-type {
  padding-left: 16px !important;
}
.impact-tracing-table table .mat-cell:last-of-type {
  padding-right: 16px !important;
}
.impact-tracing-table table tr .mat-cell {
  padding: 12px;
}
.impact-tracing-table table tr:hover.mat-row td {
  background-color: var(--blue-lighten6) !important;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

.market-intelligence-card .mat-figure {
  align-items: stretch;
}
.market-intelligence-card .mat-figure .market-card:hover {
  border: 1px solid var(--blue-lighten4);
  border-radius: 10px;
}

.scss-academy-card .mat-figure .market-card:hover {
  border: 1px solid var(--blue-lighten4);
  border-radius: 10px;
}

/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}

.mat-drawer.medium {
  width: 500px;
}
.mat-drawer.large {
  width: 650px;
}

.drawer-header .drawer-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  color: #0c121d;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
.drawer-header .drawer-close-icon {
  color: #61656c;
}

.drawer-details {
  height: calc(100vh - 164px);
  overflow: auto;
}

.clear-filter-btn {
  position: absolute !important;
  top: 20px;
  right: 64px;
  z-index: 1;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
.workspace-side-nav {
  width: 200px;
}
.workspace-side-nav .mat-expansion-panel .mat-expansion-panel-header {
  padding: 8px 12px !important;
  height: fit-content !important;
}
.workspace-side-nav .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
  margin-right: 0 !important;
}
.workspace-side-nav .mat-expansion-panel .mat-expansion-indicator::after {
  padding: 4px !important;
  position: relative;
  top: -4px;
}
.workspace-side-nav .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 4px 0 0 !important;
}
.workspace-side-nav .mat-expansion-panel-header {
  height: 40px;
}
.workspace-side-nav .mat-expansion-panel-header:hover {
  background-color: transparent !important;
}

/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   COLORS
---------------------------------*/
/*===============================
  Social Colors
---------------------------------*/
/*===============================
  Conplus Brand Colors
---------------------------------*/
/* ==============================
   Border Radius Properties
---------------------------------*/
/* ==============================
   Font Size Properties
---------------------------------*/
/* ==============================
   Box Shadow Properties
---------------------------------*/
/* ==============================
   Spacers Properties
---------------------------------*/
/* ==============================
   Divider Properties
---------------------------------*/
/* ==========================================================================
   Mixins
    - Border Radius Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Box Shadow Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Transition Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Animation Delay Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Positioning Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Rotate Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Font Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Images Sizing Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Icon Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Flex Box Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Width Properties here
   ========================================================================== */
/* ==========================================================================
   Mixins
    - Mat Icon Properties here
   ========================================================================== */
.mat-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.mat-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

/* ==========================================================================
   Mixins
    - Image size Properties here
   ========================================================================== */
.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}