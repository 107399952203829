@import '../includes';

/* Material Checkbox style overwrite here */
.mat-checkbox {
  .mat-checkbox-frame {
    border-color: #aaacb0;
  }

  .mat-checkbox-label {
    @include font(14px, 20px, 500, #3d414a);
  }

  .mat-checkbox-layout {
    margin: 0;
  }

  &.mat-secondary.mat-checkbox-checked,
  &.mat-secondary.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background-color: #00abbb;
    }
  }
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #00abbb !important;
}

//Todo todoCss: Need to move utilities file after an table scss removed from utilities
/* for tags*/
.tag {
  @include font(13px, 20px, 500, #3d414a);
  padding: 4px 8px;
  @include border-radius(6px);
  min-width: 93px;
  display: inline-block;
  text-align: center;
  letter-spacing: normal;
  white-space: nowrap;
  &.tag-void,
  &.tag-on-going,
  &.tag-inprogress,
  &.tag-pending {
    background-color: #fffae5;
    color: #e88b00;
  }

  &.tag-won,
  &.tag-accepted,
  &.tag-closed,
  &.tag-paid,
  &.tag-executed,
  &.tag-active,
  &.tag-approved,
  &.tag-completed,
  &.tag-pitch-invited {
    background-color: #f0faf6;
    color: #00af62;
  }

  &.tag-boarding-on-going,
  &.tag-open,
  &.tag-start,
  &.tag-started,
  &.tag-sow-invited,
  &.tag-invited,
  &.tag-not-yet-sent,
  &.tag-draft {
    background-color: #ecf9fa;
    color: #00abbb;
  }

  &.tag-uncollectible,
  &.tag-change-request,
  &.tag-inactive,
  &.tag-due,
  &.tag-requested,
  &.tag-rejected,
  &.tag-update-required,
  &.tag-changes-submitted,
  &.tag-stopped,
  &.tag-off-boarding {
    background-color: #fdf0f3;
    color: #e00034;
  }
}

//Todo todocss: need to move utilities file after an table scss removed from utilities
/* for breadcrumbs*/
.breadcrumbs {
  li {
    @include font(13px, 18px, 500, #85888e);

    &:not(:last-child)::after {
      content: '/';
      padding: 0 6px;
    }

    .route-link:hover {
      @include font(13px, 18px, 500, #0AA1FB);
      text-decoration: underline;
    }

    &.more-actions {
      display: flex;
      align-items: center;
      padding: 4px 6px;

      &:hover,
      &[aria-expanded='true'] {
        border-radius: 3px;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    &:last-child {
      @include font(13px, 18px, 600, #0c121d);
    }
  }
}
