@import '../includes';

.table-with-white-bg,
.table-with-grey-bg {
  .mat-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #e7e7e8;
    border-radius: 8px;
    overflow: hidden;

    tr {
      th {
        @include font(13px, 16px, 600, #0c121d);
        text-transform: uppercase;
        background-color: #fff;
      }

      td {
        @include font(13px, 20px, 400, #61656c);
      }

      &.mat-header-row {
        height: 40px;

        .mat-header-cell {
          border-bottom: 1px solid #e7e7e8;
        }
      }

      &.mat-row {
        height: 48px !important;
        background-color: #fff;

        .mat-cell {
          border-bottom-color: #e7e7e8;
        }

        &:hover,
        &.active {
          background-color: #f7fafc;
        }

        &:last-child .mat-cell {
          border-bottom: none;
        }
      }
    }

    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type {
      padding-left: 12px;
    }

    th.mat-header-cell:last-of-type,
    td.mat-cell:last-of-type {
      padding: 8px 12px;
    }
  }
}

.table-with-grey-bg .mat-table tr th {
  background-color: #f2f7fa;
}

.table-data {
  @include font(13px, 20px, 600, #0c121d);
}

/* Table selection box styles here */
.table-action {
  padding: 6px 12px;
  background-color: #fff;
  border-radius: 5px;

  .count {
    @include size(30px);
    @include font(13px, 30px, 700, #0c121d);
    border-radius: 6px;
    background: #f6f6f6;
  }

  .page-title1 {
    @include font(13px, 18px, 700, #000000);
  }

  .info {
    @include font(14px, 20px, 500, #0c121d);
  }

  &-grey {
    background-color: #f2f6f9;

    .count {
      background: #fff;
    }
  }
}

/* Strategic priorities table styles here */
.strategic-priorities-table-responsive {
  height: calc(100vh - 202px) !important;

  tr {
    th,
    td {
      &:nth-child(1) {
        width: 60px;
      }

      &:nth-child(2) {
        @include minMaxWidth(100px);
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(8) {
        @include minMaxWidth(150px);
      }

      &:last-child {
        width: 100px;
        text-align: center;
        padding: 9px 12px;
      }

      .more-actions,
      .view-action {
        color: #7f7f7f;
        display: none;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }

    &:hover {
      .more-actions,
      .view-action {
        display: inline-table;
      }
    }

    .more-actions[aria-expanded='true'] {
      display: inline-table;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &.table-select-item {
    height: calc(100vh - 256px) !important;
  }
}

.strategic-priorities-table-responsive-no-select {
  .mat-table {
    .mat-header-row {
      .mat-header-cell {
        &:nth-child(1) {
          min-width: 100px;
        }

        &:nth-child(2) {
          min-width: 200px;
        }

        &:nth-child(3) {
          min-width: 400px;
        }

        &:nth-child(4) {
          min-width: 200px;
        }

        &:nth-child(5) {
          min-width: 200px;
        }

        &:nth-child(6) {
          min-width: 100px !important;
        }

        &:last-of-type {
          text-align: center;
        }
      }
    }
  }

  table {
    tr {
      &.mat-row {
        td {
          &.mat-cell:last-of-type {
            div {
              div {
                display: none !important;
                font-size: 13px;
                color: #7f7f7f !important;
                width: 28px;
                height: 28px;
                border-radius: 50px;
                line-height: 0;

                &:hover {
                  background: #e3e3e3;
                }
              }
            }
          }
        }
      }

      &:hover {
        background-color: #f6f6f6 !important;

        &.mat-row {
          td {
            &.mat-cell:last-of-type {
              div {
                div {
                  display: flex !important;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Legal entities table styles here */
.legal-entity-table {
  height: calc(100vh - 330px) !important;

  tr {
    th,
    td {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        @include minMaxWidth(150px);
      }

      &:nth-child(4) {
        @include minMaxWidth(130px);
      }

      &:nth-child(7),
      &:nth-child(9) {
        @include minMaxWidth(120px);
      }

      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(8) {
        @include minMaxWidth(100px);
      }

      &:nth-child(10) {
        width: 100px;
      }

      &:last-child {
        text-align: center;
      }

      .more-actions {
        color: #7f7f7f;
        display: none;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }

    &:hover {
      .more-actions {
        display: inline-table;
      }
    }

    .more-actions[aria-expanded='true'] {
      display: inline-table;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

// Project Steps Demand Table
.demand-other-tab {
  .dashboard-box {
    .view-projects-tab {
      .mat-tab-body {
        .table-section {
          height: calc(100vh - 464px);
        }
      }
    }
  }
}

// Legal Entity Table
.entityscreen {
  .admin-entity-billing-table-responsive {
    height: calc(100vh - 444px) !important;
  }
}

// Legal Entity Table
.entityscreen {
  .admin-entity-billing-table-responsive {
    height: calc(100vh - 444px) !important;
  }
}

.reference-document {
  .table {
    .sow-document {
      height: calc(100vh - 500px) !important;
    }
  }
}

.project-manage-invocing-tab {
  .table-section {
    height: calc(100vh - 338px) !important;
  }
}

.selection-group {
  .result-table {
    .mat-table {
      tr {
        th,
        td {
          &:first-child {
            width: 80px;
            padding: 9px 12px;
          }

          &:nth-child(2),
          &:nth-child(5),
          &:nth-child(7) {
            @include minMaxWidth(150px);
          }

          &:nth-child(3),
          &:nth-child(4) {
            @include minMaxWidth(80px);
          }

          &:nth-child(6) {
            @include minMaxWidth(100px);
          }

          &:nth-child(8) {
            @include minMaxWidth(120px);
          }

          &:last-child {
            width: 100px;
            padding: 9px 12px;
            text-align: center;
          }
        }
      }

      .selection-drag {
        color: #7f7f7f;
        display: none;
      }

      .more-actions {
        color: #7f7f7f;
        display: none;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }

    &:hover {
      .selection-drag {
        display: inline-table !important;
      }
      .more-actions {
        display: inline-table !important;
      }
    }

    .more-actions[aria-expanded='true'] {
      display: inline-table !important;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

// Table Header Sticky

.currency-header-sticky {
  height: calc(100vh - 356px) !important;
  .currency-conversion {
    height: auto !important;
    max-height: calc(100vh - 406px);
    border: 1px solid var(--border-light-grey);
    border-radius: 8px;
  }
  .mat-table {
    border: none;
    overflow: auto !important;
    tr {
      &:last-of-type {
        border-bottom: none;
        td {
          border-bottom: none;
        }
      }
    }
  }
}

.impact-tracing-table {
  table {
    th {
      background-color: var(--blue-lighten6) !important;
    }
  }
}

.impact-tracing-table {
  table {
    .mat-header-row {
      height: 40px;
      background-color: var(--blue-lighten6) !important;
      .mat-header-cell {
        padding: 12px;
        color: #424242 !important;
        font-size: 13px !important;
        font-weight: 600 !important;
        text-transform: uppercase;
        &:first-of-type {
          padding-left: 16px !important;
        }

        &:last-of-type {
          padding-right: 16px !important;
        }
      }
    }

    .mat-cell {
      padding: 12px;
      &:first-of-type {
        padding-left: 16px !important;
      }

      &:last-of-type {
        padding-right: 16px !important;
      }
    }
    tr {
      .mat-cell {
        padding: 12px;
      }

      &:hover {
        &.mat-row {
          td {
            background-color: $blue-lighten6 !important;
          }
        }
      }
    }
  }
}
