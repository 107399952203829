/* ==============================
   COLORS
---------------------------------*/
$primary-color: var(--primary-color);
$primary-color-85: var(--primary-color-85);
$primary-color-70: var(--primary-color-70);
$primary-color-50: var(--primary-color-50);
$primary-color-40: var(--primary-color-40);
$primary-color-20: var(--primary-color-20);
$primary-color-16: var(--primary-color-16);
$primary-color-15: var(--primary-color-15);
$primary-color-12: var(--primary-color-12);
$primary-color-10: var(--primary-color-10);
$primary-color-06: var(--primary-color-06);
$primary-color-05: var(--primary-color-05);
$primary-color-03: var(--primary-color-03);

$primary-light: var(--primary-light);

$secondary-color: var(--secondary-color);
$secondary-color-04: var(--secondary-color-04);

$background-color: var(--background-color);
$background-white: var(--text-white-primary);
$background-light-blue: var(--background-light-blue);

$text-white-primary: var(--white);

$text-grey-primary: var(--text-primary);

$text-grey-primary-1: var(--text-grey-primary-1);
$text-grey-primary-2: var(--text-grey-primary-2);
$text-grey-primary-3: var(--text-grey-primary-3);
$text-grey-primary-4: var(--text-grey-primary-4);

$text-grey-secondary: var(--text-grey-secondary);
$text-grey-secondary-50: vsr(--text-grey-secondary-50);

$text-grey-secondary-1: var(--text-grey-secondary-1);
$text-grey-secondary-1-08: var(--text-grey-secondary-1-08);
$text-grey-secondary-9: var(--grey-lighten-9);
$text-grey-secondary-10: var(--grey-lighten-10);

$text-grey-colors: var(--light-grey-colors);

$text-grey-hint: var(--text-grey-hint);
$text-grey-hint-20: var(--text-grey-hint-20);

$text-grey-content: var(--text-grey-content);
$text-grey-content-10: var(--text-grey-content-10);

$text-grey-line: var(--text-grey-line);
$text-grey-line-65: var(--text-grey-line-65);

$text-grey-progress: var(--text-grey-progress);

$text-grey-secondary-14: var(--grey-lighten-14);

$text-tertiary: var(--text-tertiary);
$text-warn: var(--text-warn);
$text-light: var(--text-light);

$grey-darken-1: var(--grey-darken-1);
$grey-darken-2: var(--grey-darken-2);
$grey-darken-3: var(--grey-darken-3);
$grey-darken-4: var(--grey-darken-4);
$grey-darken-5: var(--grey-darken-5);
$grey-darken-6: var(--grey-darken-6);
$grey-darken-7: var(--grey-darken-7);
$grey-darken-8: var(--grey-darken-8);
$grey-lighten-1: var(--grey-lighten-1);
$grey-lighten-2: var(--grey-lighten-2);
$grey-lighten-3: var(--grey-lighten-3);
$grey-lighten-4: var(--grey-lighten-4);
$grey-lighten-5: var(--grey-lighten-5);
$grey-lighten-6: var(--grey-lighten-6);
$grey-lighten-7: var(--grey-lighten-7);
$grey-lighten-10: var(--grey-lighten-10);
$grey-lighten-11: var(--grey-lighten-11);
$grey-lighten-12: var(--grey-lighten-12);
$grey-lighten-13: var(--grey-lighten-13);
$grey-lighten-15: var(--grey-lighten-15);

$grey-lighten-2-60: var(--grey-lighten-2-60);
$grey-lighten-2-50: var(--grey-lighten-2-50);
$grey-lighten-2-16: var(--grey-lighten-2-16);
$grey-lighten-2-05: var(--grey-lighten-2-05);
$grey-lighten-6-01: var(--grey-lighten-6-01);

$skeleton-loader: var(--skeleton-loader);
$dividerGrey: var(--dividerGrey);
$dividerPurple: var(--dividerPurple);

$red: var(--red);
$red-40: var(--red-40);
$red-34: var(--red-color-34);
$red-20: var(--red-20);
$red-16: var(--red-16);
$red-15: var(--red-15);
$red-14: var(--red-14);
$red-10: var(--red-10);
$red-08: var(--red-08);
$red-05: var(--red-05);

$red-lighten1: var(--red-lighten1);
$red-lighten2: var(--red-lighten2);
$red-lighten3: var(--red-lighten3);
$red-lighten4: var(--error-red);

$green: var(--green);
$green-50: var(--green-50);
$green-40: var(--green-40);
$green-20: var(--green-20);
$green-16: var(--green-16);
$green-15: var(--green-15);
$green-10: var(--green-10);
$green-05: var(--green-05);
$green-04: var(--green-04);
$green-03: var(--green-03);

$green-lighten1: var(--green-lighten1);

$green-lighten2: var(--green-lighten2);
$green-lighten12: var(--light-green-12);
$green-lighten2-40: var(--green-lighten2-40);
$green-lighten-bg: var(--green-lighten-bg);

$green-lighten3: var(--green-lighten3);
$green-dark: var(--green-dark);

$blue: var(--blue);
$blue-40: var(--blue-40);
$blue-20: var(--blue-20);
$blue-16: var(--blue-16);
$blue-15: var(--blue-15);
$blue-05: var(--blue-05);
$blue-06: var(--blue-lighten4);
$blue-01: var(--blue-01);
$blue-12: var(--blue-12);
$blue-bg-light: var(--blue-bg-light);

$blue-lighten1: var(--blue-lighten1);
$blue-lighten2: var(--blue-lighten2);
$blue-lighten3: var(--blue-lighten3);
$blue-lighten6: var(--blue-lighten6);

$warn-color: var(--warn-color);
$warn-color-50: var(--warn-color-50);

$warning-color-dark: var(--warning-color-dark);
$warning-color-lighten: var(--warning-color-lighten);

$pink-color: var(--pink-color);

$yellow: var(--yellow);
$yellow-20: var(--yellow-20);

$pink-legend-color: var(--pink-legend-color);

$dark-green: var(--dark-green);
$dark-green-80: var(--dark-green-80);
$dark-green-63: var(--dark-green-63);
$dark-green-20: var(--dark-green-20);
$dark-green-16: var(--dark-green-16);
$dark-green-14: var(--dark-green-14);
$dark-green-12: var(--dark-green-12);
$dark-green-10: var(--dark-green-10);

$dark-green-lighten: var(--dark-green-lighten);

$orange-color: var(--orange-color);
$orange-color-40: var(--orange-color-40);
$orange-color-20: var(--orange-color-20);
$orange-color-16: var(--orange-color-16);
$orange-color-15: var(--orange-color-15);
$orange-color-14: var(--orange-color-14);
$orange-color-10: var(--orange-color-10);
$orange-color-05: var(--orange-color-05);

$light-green: var(--light-green);

$peak-green: var(--peak-green);
$peak-green-15: var(--peak-green-15);
$peak-green-10: var(--peak-green-10);
$peak-green-08: var(--peak-green-08);
$peak-green-05: var(--peak-green-05);

$dark-pink: var(--dark-pink);
$dark-pink-15: var(--dark-pink-15);
$dark-pink-05: var(--dark-pink-05);

$dark-yellow: var(--dark-yellow);
$dark-yellow-15: var(--dark-yellow-15);
$dark-yellow-05: var(--dark-yellow-05);

$dark-red: var(--dark-red);
$dark-red-05: var(--dark-red-05);

$royal-blue: var(--royal-blue);
$royal-blue-05: var(--royal-blue-05);

$dark-blue: var(--dark-blue);
$dark-blue-05: var(--dark-blue-05);

$darken-blue: var(--darken-blue);

/*===============================
  Social Colors
---------------------------------*/

$fb-color: var(--fb-color);
$tw-color: var(--tw-color);
$gplus-color: var(--gplus-color);
$yt-color: var(--yt-color);
$li-color: var(--li-color);
$pin-color: var(--pin-color);
$ins-color: var(--ins-color);
$git-color: var(--git-color);
$comm-color: var(--comm-color);
$vk-color: var(--vk-color);
$drib-color: var(--drib-color);
$note-bg-color: var(--note-bg-yellow);

/*===============================
  Conplus Brand Colors
---------------------------------*/

$white: var(--white);
$white-color-76: var(--white-color-76);
$white-color-60: var(--white-color-60);
$white-color-56: var(--white-color-56);
$white-color-20: var(--white-color-20);
$white-color-21: var(--white-color-21);
$white-color-17: var(--white-color-17);

$black-color: var(--black-color);
$black-color-85: var(--black-color-85);
$black-color-80: var(--black-color-80);
$black-color-45: var(--black-color-45);
$black-color-40: var(--black-color-40);
$black-color-36: var(--black-color-36);
$black-color-30: var(--black-color-30);
$black-color-24: var(--black-color-24);
$black-color-21: var(--black-color-21);
$black-color-20: var(--black-color-20);
$black-color-16: var(--black-color-16);
$black-color-15: var(--black-color-15);
$black-color-12: var(--black-color-12);
$black-color-10: var(--black-color-10);
$black-color-07: var(--black-color-07);
$black-color-06: var(--black-color-06);
$black-color-05: var(--black-color-05);
$black-color-03: var(--black-color-03);
$black-color-54: var(--black-color-54);

$black-opacity-10: var(--black-opacity-10);

//border

$border-grey: var(--border-grey);

// New Colors Oct 2023

// Hex
$granite-gray: #6c6861;
$shuttle-grey: #61656c;
$charcoal-grey: #424242;
$error-primary-color: #e00034;
$orange-primary-color: #ff7700;
$white-smoke: #f2f6f9;
$white-lilac: #f6f6f6;
$white-01: #e7e7e8;
$white-02: #eef3f7;
$white-03: #f3f6fa;
$white-04: #F7FAFC;
$text-primary-01: #0c121d;
$text-hint: #85888e;
$error-bg: #FBE0E7;
$error-bg-light: #FDF0F3;
$error-light: #FA003A;
$error-lighter: #FDF0F3;
$success-primary-color: #00af62;
$success-light: #F0FAF6;
$success-hover: #E0F5EC;
$primary-color-09: #fcefc0;
$primary-color-11: #fefbe7;
$warning-color: #E88B00;
$notice-color: #f6be2c33;
$notice-color-1: #f6cc001a;
$charcoal-grey-secondary :#3D414A;
$aluminium-grey: #AAACB0;
$cyan-01: #cdf5f8;
$lavender-mist: #e6ebee;
$blue-lighten-10:#D5DDE2;
// Rgba

$fulvous-opacity-12: rgba(232, 139, 0, 0.12);
$error-primary-color-01: rgba(224, 0, 52, 0.12);
$grey-opacity-43: rgba(127, 127, 127, 0.43);
$blue-lighten7: rgba(0, 171, 187, 0.12);
$blue-lighten8: rgba(0, 171, 187, 1);
$primary-color-04: rgba(232, 139, 0, 1);
$light-green-12: rgba(0, 175, 100, 1);
$light-green-13: rgba(0, 175, 100, 0.12);