@import '../includes';

/* Material Slide Toggle style overwrite here */
.mat-slide-toggle.mat-secondary {
  .mat-slide-toggle-bar {
    border-radius: 50px;
    border: 1px solid #e7e7e8;
    background: #f7fafc;
    width: 36px;
    height: 20px;

    .mat-slide-toggle-thumb-container {
      width: 13px;
      height: 13px;
      top: 2.5px;
      left: 3px;

      .mat-slide-toggle-thumb {
        height: 13px;
        width: 13px;
        box-shadow: none;
        border-radius: 50%;
        background-color: #aaacb0;
      }
    }
    .mat-slide-toggle-ripple {
      visibility: hidden;
    }
  }

  .mat-slide-toggle-content {
    @include font(14px, 20px, 500, #3d414a);
  }

  &.mat-checked {
    .mat-slide-toggle-bar {
      border: 1px solid #cdf5f8;
      background: #ecf9fa;
    }
    .mat-slide-toggle-thumb {
      background-color: #00abbb !important;
    }
  }
}
