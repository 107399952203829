@import '../includes';
/* Material Button style overwrite here */

button.mat-button,
a.mat-button {
  color: #00abbb;
  transition: all 0.3s;
  border-radius: 6px;
  font-weight: 600;
  letter-spacing: 0.28px;
  outline: none;
  border: none;
  line-height: 20px;
  padding: $spacer10;
  font-family: 'Open Sans', sans-serif;

  &.full-width {
    width: 100%;
  }

  &.medium {
    padding: 8px $spacer10;
  }

  &.small {
    padding: 6px $spacer10;
  }

  &.mat-primary {
    color: $primary-color;

    &:hover {
      background-color: #FFF7F0;
    }
  }

  &.mat-secondary {
    color: #00abbb;

    &:hover {
      background-color: #ecf9fa;
    }
  }

  &.mat-calendar-period-button {
    color: $text-primary-01;
  }

  &[disabled] {
    color: #c2c4c6 !important;
    background-color: transparent !important;
    cursor: not-allowed;
  }
}

button.mat-flat-button,
a.mat-flat-button {
  background-color: $text-white-primary;
  border: none !important;
  color: $text-white-primary;
  transition: all 0.3s;
  border-radius: 6px;
  padding: $spacer10;
  font-weight: 600;
  letter-spacing: 0.28px;
  outline: none;
  border: none;
  line-height: 20px;
  font-family: 'Open Sans', sans-serif;

  &.full-width {
    width: 100%;
  }

  &.medium {
    padding: 8px $spacer10;
  }

  &.small {
    padding: 6px $spacer10;
  }

  &.mat-primary {
    background: $primary-color;
    &:hover {
      background-color: #ff9233;
    }
  }

  &.mat-secondary {
    background-color: #00abbb;
    &:hover {
      background-color: #2ebbc9;
    }
  }

  &.mat-accent {
    background-color: #0c121d;
    &:hover {
      background-color: #61656c;
    }
  }

  &.mat-warn {
    background-color: #e00034;
    &:hover {
      background-color: #fa003a;
    }
  }

  &[disabled] {
    background-color: #c2c4c6 !important;
    color: $text-white-primary !important;
    cursor: not-allowed;
  }
}

button.mat-stroked-button,
a.mat-stroked-button {
  border: 1px solid #0c121d;
  color: #0c121d;
  transition: all 0.3s;
  border-radius: 6px;
  padding: 9px $spacer10;
  font-weight: 600;
  letter-spacing: 0.28px;
  outline: none;
  line-height: 20px;
  font-family: 'Open Sans', sans-serif;

  &.full-width {
    width: 100%;
  }

  &.medium {
    padding: 7px $spacer10;
  }

  &.small {
    padding: 5px $spacer10;
  }

  &.mat-primary {
    border: 1px solid $primary-color;
    color: $primary-color;

    &:hover {
      background-color: #fff7f0;
    }
  }

  &.mat-secondary {
    border: 1px solid #00abbb;
    color: #00abbb;

    &:hover {
      background-color: #f0fafb;
    }
  }

  &.mat-accent {
    border: 1px solid #0c121d;
    color: #0c121d;

    &:hover {
      background-color: #e7e7e8;
    }
  }

  &.mat-warn {
    border:1px solid #E00034;
    color: #E00034;

    &:hover {
      background-color: #FDF0F3;
    }
  }

  &[disabled] {
    background-color: #c2c4c6 !important;
    border-color: #c2c4c6 !important;
    color: $text-white-primary !important;
    cursor: not-allowed;
  }
}

button.icon-button .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

button.mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #3d414a;
}

// Todo:: Added for button issue
.button-section {
  .text-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.delete-modal {
  .text-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}


.pdf-to-container,
.pdf-container{
      .mat-icon-button {
        @include border-radius(50%);
        @include size(32px);
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
          background-color: #F2F7FA;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .mat-button-wrapper{
          @include size(20px);
          display: flex;
        }
        .mat-icon {
          color: #aaacb0;
        }
      }
}
