@import '../includes';

/* Material Chip Lists style overwrite here */
.mat-chip-list {
  &-wrapper {
    .mat-chip {
      @include font(13px, 18px, 600, #3d414a);
      background-color: #f6f6f6;
      border-radius: 6px;
      border: none;
      height: auto;

      .mat-chip-remove {
        @include size(18px !important);
        color: #85888e;
        margin-left: 6px;
        opacity: 1;
        &:hover {
          opacity: 1 !important;
        }
      }

      &::after {
        background: #e6ebee;
        border: none;
        transition: none;
      }

      &:hover::after {
        opacity: 1;
        z-index: -1;
      }
    }
  }
}

.mat-chip-list-wrapper input.mat-input-element {
  background: none;
  border: 1px solid $primary-color-40 !important;
  color: $primary-color;
  white-space: normal;
  word-break: break-word;
  line-height: 1.3;
  outline: none !important;
  height: unset !important;
}

.table-chip-list {
  .mat-chip-list-wrapper {
    flex-wrap: wrap !important;
    .mat-chip {
      white-space: nowrap !important;
      word-break: keep-all !important;
    }
  }
}

// single level select styles here
.single-select-chip {
  .mat-chip-list-wrapper {
    height: fit-content;
    overflow: auto;
  }
}

.mat-chip-organize {
  .mat-chip-list-wrapper .mat-standard-chip:hover {
    background: none;
  }

  .mat-chip.mat-standard-chip .mat-chip-remove {
    @include size(20px);
    @include flexCenter(flex, center, center);
    border-radius: 50%;
    &:hover {
      background: rgba(255, 119, 0, 0.12);
    }
  }
}

.select-filter-chip {
  align-items: center;
  .mat-chip-list-wrapper {
    margin: 0 !important;
  }
}