@import '../includes';

/* Material Radio Button style overwrite here */
.mat-radio-button.mat-secondary {
  .mat-radio-label {
    margin: 0;
  }

  .mat-radio-outer-circle {
    border-color: #aaacb0;
  }
  .mat-radio-label-content {
    @include font(14px, 20px, 500, #3d414a);
  }

  & + .mat-radio-button {
    margin-left: 0;
  }

  .mat-ripple-element {
    background-color: #00abbb !important;
  }

  &.mat-radio-checked {
    .mat-radio-outer-circle {
      border-color: #00abbb;
    }
    .mat-radio-inner-circle {
      background-color: #00abbb;
    }
  }
}
