@import '../includes';

/* Material Menu style overwrite here */
.mat-menu-panel {
  border-radius: 6px !important;
  border: 1px solid #f7fafc;
  background: #fff;
  box-shadow: 10px 0px 30px 0px rgba(0, 0, 0, 0.05), 1px 1px 5px 0px rgba(0, 0, 0, 0.05);
  padding: 4px;
  min-width: 140px !important;
  min-height: unset !important;

  .mat-menu-item {
    border-radius: 4px;
    @include font(14px, 20px, 500, #61656c);
    padding: 8px 6px;
    height: 40px;
    display: flex;
    align-items: center;

    .mat-icon {
      color: #85888e;
      font-size: 20px;
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    &.delete-action,
    &.stop-action {
      color: #e00034;
      .mat-icon {
        color: #e00034;
      }
    }

    &.validate-action {
      color: #00af62;
      .mat-icon {
        color: #00af62;
      }
    }

    &:hover {
      background-color: #f7fafc;
    }
  }

  .mat-menu-content {
    .mat-menu-item {
      &:focus {
        outline: none !important;
      }
    }
  }
}

// Notification Mat Menu Styles Here
.notification-conatiner {
  width: 480px !important;
  max-width: 480px !important;
  padding: 0 !important;
  .mat-menu-content {
    .content-section {
      width: 100% !important;
      height: 366px !important;
    }
    .viewall-section {
      height: 50px;
      .footer {
        @include font(13px, normal, 700, var(--blue-lighten4));
      }
    }
  }

  .request {
    width: 100% !important;
  }
}

.emoji-menu {
  max-width: fit-content !important;
  padding: 0;
}

.mat-menu-panel {
  &.chip-more-vert {
    padding: 6px;
    border-radius: 4px;
    box-shadow: 0px 4px 12px 0px $black-opacity-10;
    .mat-menu-item {
      border-radius: 4px;
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}
