@import '../includes';

/* Mat form field Input text box style overwrite here */
mat-form-field.mat-form-field-type-mat-input {
  input.mat-input-element {
    margin-top: 0;
    vertical-align: middle;
  }

  .mat-form-field-flex {
    border: 1px solid #dbdbdd;
    border-radius: 6px;
    align-items: center;
    background: #fff;
  }

  &.mat-focused .mat-form-field-flex {
    border: 1px solid #00abbb;
  }

  // ERROR message and placement
  .mat-form-field-subscript-wrapper {
    margin-top: 0;

    .mat-error {
      color: #e00034 !important;
      font-size: 13px;
      line-height: 18px;
      font-weight: 500;
      margin-top: 6px;
      overflow: visible;
    }

    .mat-form-field-hint-wrapper {
      bottom: 0;
      padding-right: 2px;
    }
  }

  &.mat-form-field-invalid .mat-form-field-subscript-wrapper {
    position: relative;
  }

  // &.single-border {
  //   .mat-form-field-flex {
  //     border: none;
  //     border-bottom: 1px solid $grey-lighten-2;
  //     border-radius: 0;
  //   }

  //   // Change error background and border on focus
  //   &:focus,
  //   &.mat-focused {
  //     .mat-form-field-flex {
  //       border-color: $primary-color;
  //       box-shadow: none;
  //     }

  //     &.mat-form-field-invalid .mat-form-field-flex {
  //       background-color: transparent;
  //       box-shadow: none;
  //     }
  //   }

  //   // ERROR state styling when not hovered or focused
  //   &.mat-form-field-invalid .mat-form-field-flex {
  //     border-bottom-color: $red;
  //   }
  // }
}

/* Search and clear section style here */
.search-clear {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      border-bottom: $border-1 !important;
      padding: $spacer8 0 !important;
      border: none;
    }

    .mat-form-field-prefix {
      .search-icon {
        i {
          @include font(18px, 1, normal, $text-grey-content);
        }
      }
    }

    .mat-form-field-suffix {
      .close-icon {
        @include font(11px, 1, 400, $text-grey-content);
        display: flex;
        align-items: center;

        i {
          font-size: 13px;
          background: $text-grey-content;
          border-radius: $border-radius-circle;
          color: $text-white-primary;
          margin-left: $spacer16;
          cursor: pointer;
        }
      }
    }
  }
}

// capabilites styles here
.capabilities-input {
  mat-form-field {
    margin-bottom: 0;

    &.mat-form-field-type-mat-input {
      .mat-form-field-flex {
        border: none;
      }

      &.mat-form-field-invalid {
        .mat-form-field-flex {
          background-color: transparent;
        }
      }
    }
  }
}

//chat send message container styles
.send-message-container {
  &.mat-form-field-type-mat-input {
    .mat-form-field-flex {
      border-radius: 50px !important;
      height: 52px;
      padding: 16px;
    }
  }
}

// company info styles here
.company-info-input {
  &.mat-form-field-type-mat-input {
    .mat-form-field-flex {
      border: none;
      flex-direction: row-reverse;
    }
  }

  &.mat-form-field-invalid {
    .mat-form-field-flex {
      background-color: transparent !important;
    }
  }
}

.drawer-input-primary {
  &.mat-form-field {
    .mat-form-field-wrapper {
      margin: 0;
      width: 200px;

      .mat-form-field-flex {
        .mat-form-field-outline {
          top: 0;

          .mat-form-field-outline-start,
          .mat-form-field-outline-gap,
          .mat-form-field-outline-end {
            border-width: 1px;
          }
        }

        .mat-form-field-infix {
          width: unset;

          .mat-input-element {
            padding: 0;
            line-height: 40px;
          }
        }
      }
    }

    &:hover,
    &.mat-focused {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-outline {
            color: $primary-color;
          }
        }
      }
    }
  }
}

.impact-tracking-input {
  mat-form-field {
    &.mat-form-field-type-mat-input {
      input {
        &.mat-input-element {
          background: var(--white) !important;
          border: none;
        }
      }

      &.mat-form-field-disabled {
        .mat-form-field-flex {
          border-color: var(--white);
        }
      }
    }

    &.mat-form-field {
      margin: 0 !important;
    }

    &.mat-form-field-type-mat-select {
      .mat-select {
        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}

.dynamic-input {
  .mat-form-field-type-mat-input {
    input {
      &.mat-input-element {
        font-size: 16px;
        line-height: normal;
        height: 40px;
        padding: 10px 8px 4px 0;
      }
    }
  }
}

// Selection Criteria Table Styles Here
.selection-table,
.rfp-selection-table {
  .mat-table {
    .text-right {
      .mat-form-field {
        .mat-form-field-infix {
          max-width: 104px;
          width: 100%;
          height: 36px;
        }
      }
    }

    .text-left {
      .mat-form-field {
        margin-bottom: 0 !important;

        .mat-form-field-infix {
          height: 36px;
        }
      }
    }
  }
}

.template-editor-name {
  mat-form-field {
    margin-bottom: 0 !important;

    &.mat-form-field-type-mat-input {
      .mat-form-field-flex {
        border-radius: 6px;
      }

      input {
        &.mat-input-element {
          height: 40px;
        }
      }
    }

    &.mat-form-field-type-mat-select {
      .mat-select {
        border-radius: 6px;
      }
    }
  }
}

.organization-input {
  .dynamic-input {
    .mat-form-field {
      margin-bottom: 0 !important;

      .mat-form-field-flex {
        border: none !important;
        border-bottom: 1px solid #dbdbdd;
        border-radius: 0 !important;

        .mat-form-field-infix {
          .mat-input-element {
            padding: 10px 16px 4px 0 !important;
          }
        }
      }
    }

    form {
      height: fit-content !important;
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-underline {
        display: none;
      }
    }
  }
}

// TODO:- Need to confirm if needed
// .milestone-link {
//   .milestone-details {
//     .mat-form-field {
//       .mat-form-field-flex {
//         height: 60px;
//       }
//     }
//   }
// }

// .other-markers {
//   .mat-form-field {
//     .mat-form-field-flex {
//       height: 60px;
//     }
//   }
// }
