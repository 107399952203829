/* ==============================
   CHECKOUT SPECIFIC COLORS
---------------------------------*/

:root {
  --primary-color: rgb(133, 0, 65);
  --primary-color-85: rgba(133, 0, 65, 0.85);
  --primary-color-70: rgba(133, 0, 65, 0.7);
  --primary-color-50: rgba(133, 0, 65, 0.5);
  --primary-color-40: rgba(133, 0, 65, 0.4);
  --primary-color-20: rgba(133, 0, 65, 0.2);
  --primary-color-16: rgba(133, 0, 65, 0.16);
  --primary-color-15: rgba(133, 0, 65, 0.15);
  --primary-color-12: rgba(133, 0, 65, 0.12);
  --primary-color-10: rgba(133, 0, 65, 0.1);
  --primary-color-06: rgba(133, 0, 65, 0.06);
  --primary-color-05: rgba(133, 0, 65, 0.05);
  --primary-color-03: rgba(133, 0, 65, 0.03);

  --primary-light: #f2e5ec;

  --secondary-color: rgb(103, 0, 51);
  --secondary-color-04: rgba(103, 0, 51, 0.04);

  --background-color: #f9f2f5;
  --background-light-blue: #f2f6f9;
  --bg-light-white: #F7FAFC;
  --bg-reddish-orange: #fff1e6;
  --bg-light-grey: #E6EBEE;


  --text-primary: #0C121D;
  --text-tertiary: #61656C;
  --text-warn: #E88B00;
  --text-light: #aaacb0;
  --text-hint: #85888E;
  --text-orange: #ff7700;

  --text-dark-grey: #3D414A;

  --text-white-primary: #ffffff;
  --text-grey-primary-1: #03011e;
  --text-grey-primary-2: #434850;
  --text-grey-primary-3: #11161f;
  --text-grey-primary-4: #424242;


  --text-grey-secondary: rgb(66, 66, 66);
  --text-grey-secondary-50: rgba(66, 66, 66, 0.5);

  --text-grey-secondary-1: rgb(74, 74, 74);
  --text-grey-secondary-1-08: rgba(74, 74, 74, 0.08);

  --text-grey-hint: rgb(155, 155, 155);
  --text-grey-hint-20: rgba(155, 155, 155, 0.2);

  --text-grey-content: rgb(127, 127, 127);
  --text-grey-content-10: rgba(127, 127, 127, 0.1);

  --text-grey-line: rgb(187, 186, 186);
  --text-grey-line-65: rgba(187, 186, 186, 0.65);

  --text-grey-progress: #838383;

  --grey-darken-1: #333333;
  --grey-darken-2: #666666;
  --grey-darken-3: #999999;
  --grey-darken-4: #18162d;
  --grey-darken-5: #22293d;
  --grey-darken-6: #e1e1e1;
  --grey-darken-7: #e1e1e163;
  --grey-darken-8: #06172c;
  --grey-lighten-1: #cccccc;
  --grey-lighten-2: #e2e2e2;
  --grey-lighten-3: #f7f7f7;
  --grey-lighten-5: #f0f0f0;
  --grey-lighten-4: #e9ebee;
  --grey-lighten-6: #d7d7d7;
  --grey-lighten-7: #7f7f7f;
  --grey-lighten-8: #e5e5e5;
  --grey-lighten-9: #8a8d92;
  --grey-lighten-10: #ecf9fa;
  --grey-lighten-11: #d9d9d9;
  --grey-lighten-12: #b9babd;
  --grey-lighten-13: #5b5f66;

  --grey-lighten-2-60: rgba(226, 226, 226, 0.6);
  --grey-lighten-2-50: rgba(226, 226, 226, 0.5);
  --grey-lighten-2-16: rgba(226, 226, 226, 0.16);
  --grey-lighten-2-05: rgba(226, 226, 226, 0.05);
  --grey-lighten-6-01: rgba(215, 215, 215, 0.01);

  --skeleton-loader: #f6f7f9;
  --dividerGrey: rgba(0, 0, 0, 0.3);
  --black-opacity-10: rgba(0, 0, 0, 0.1);
  --dividerPurple: rgba(100, 28, 255, 0.19);

  --red: rgb(224, 0, 52);
  --red-40: rgba(224, 0, 52, 0.4);
  --red-20: rgba(224, 0, 52, 0.2);
  --red-16: rgba(224, 0, 52, 0.16);
  --red-15: rgba(224, 0, 52, 0.15);
  --red-14: rgba(224, 0, 52, 0.14);
  --red-10: rgba(224, 0, 52, 0.1);
  --red-08: rgba(224, 0, 52, 0.08);
  --red-05: rgba(224, 0, 52, 0.05);

  --red-lighten1: rgb(255, 166, 160);
  --red-lighten2: rgb(255, 240, 240);
  --red-lighten3: rgba(224, 0, 52, 0.08);
  --green-lighten-bg: rgba(224, 245, 236, 1);

  --green: rgb(0, 173, 98);
  --green-50: rgba(0, 173, 98, 0.5);
  --green-40: rgba(0, 173, 98, 0.4);
  --green-20: rgba(0, 175, 98, 0.2);
  --green-16: rgba(0, 173, 98, 0.16);
  --green-15: rgba(0, 173, 98, 0.15);
  --green-10: rgba(0, 173, 98, 0.1);
  --green-05: rgba(0, 173, 98, 0.05);
  --green-04: rgba(0, 175, 98, 0.04);
  --green-03: rgba(0, 175, 98, 1);

  --green-lighten1: #7ed321;

  --green-lighten2: rgb(188, 238, 185);
  --green-lighten2-40: rgba(188, 238, 185, 0.4);

  --green-lighten3: rgba(0, 175, 99, 0.15);
  --green-dark: rgba(0, 175, 99, 100);

  --blue: rgb(10, 161, 251);
  --blue-40: rgba(10, 161, 251, 0.4);
  --blue-20: rgba(10, 161, 251, 0.2);
  --blue-16: rgba(10, 161, 251, 0.16);
  --blue-15: rgba(10, 161, 251, 0.15);
  --blue-05: rgba(10, 161, 251, 0.05);
  --blue-12: rgba(10, 161, 251, 0.12);
  --blue-01: rgba(10, 161, 251, 1);

  --blue-lighten1: #9cddfa;
  --blue-lighten2: #e3f2fd;
  --blue-lighten3: rgba(255, 119, 0, 0.15);
  --blue-lighten4: #00abbb;
  --blue-lighten5: #007883;
  --blue-lighten6: #f2f7fa;
  --blue-bg-light: #F0FAFB;

  --warn-color: rgb(252, 87, 94);
  --warn-color-50: rgba(252, 87, 94, 0.5);

  --warning-color-dark: #fc854a;
  --warning-color-lighten: #ffcc00;

  --pink-color: #fd73a1;

  --yellow: rgb(255, 194, 51);
  --yellow-20: rgba(255, 194, 51, 0.2);

  --pink-legend-color: rgba(224, 0, 52, 0.2);

  --dark-green: rgb(0, 171, 187);
  --dark-green-80: rgb(0, 171, 187, 0.8);
  --dark-green-20: rgb(0, 171, 187, 0.2);
  --dark-green-16: rgb(0, 171, 187, 0.16);
  --dark-green-14: rgb(0, 171, 187, 0.14);
  --dark-green-12: rgb(0, 171, 187, 0.12);
  --dark-green-10: rgb(0, 171, 187, 0.1);

  --dark-green-lighten: rgba(0, 171, 187, 0.06);

  --orange-color: rgb(255, 119, 0);
  --orange-color-40: rgba(255, 119, 0, 0.4);
  --orange-color-20: rgba(255, 119, 0, 0.2);
  --orange-color-16: rgba(255, 119, 0, 0.16);
  --orange-color-15: rgba(255, 119, 0, 0.15);
  --orange-color-14: rgba(255, 119, 0, 0.14);
  --orange-color-10: rgba(255, 119, 0, 0.1);
  --orange-color-05: rgba(255, 119, 0, 0.05);

  --light-green: #4cd964;
  --dark-green-63: #00af63;
  --light-green-12: rgba(0, 175, 99, 0.12);
  --peak-green: rgb(0, 127, 139);
  --peak-green-15: rgba(0, 127, 139, 0.15);
  --peak-green-10: rgba(0, 127, 139, 0.1);
  --peak-green-08: rgba(0, 127, 139, 0.08);
  --peak-green-05: rgba(0, 127, 139, 0.05);

  --dark-pink: rgb(189, 16, 224);
  --dark-pink-15: rgba(189, 16, 224, 0.15);
  --dark-pink-05: rgba(189, 16, 224, 0.05);

  --dark-yellow: rgb(255, 188, 0);
  --dark-yellow-15: rgba(255, 188, 0, 0.15);
  --dark-yellow-05: rgba(255, 188, 0, 0.05);

  --dark-red: rgb(208, 2, 27);
  --dark-red-05: rgba(208, 2, 27, 0.05);

  --royal-blue: rgb(0, 19, 255);
  --royal-blue-05: rgba(0, 19, 255, 0.05);

  --dark-blue: rgb(0, 82, 130);
  --dark-blue-05: rgba(0, 82, 130, 0.05);

  --darken-blue: #0066a1;

  --border-grey: #e3e3e3;
  --border-light-grey: #E7E7E8;
  --border-grey-1: #E7E7E8;

  --light-grey-colors: #aaa;

  --dot-1: #C2C4C6;

  /*===============================
    Social Colors
  ----------------------------------*/

  --fb-color: #3b5998;
  --tw-color: #55acee;
  --gplus-color: #dd4b39;
  --yt-color: #cd201f;
  --li-color: #0082ca;
  --pin-color: #c61118;
  --ins-color: #3f729b;
  --git-color: #4183c4;
  --comm-color: #30cfc0;
  --vk-color: #4c75a3;
  --drib-color: #c32361;
  --red-color-34: #e00034;
  --error-red: rgba(224, 0, 52, 0.12);
  --note-bg-yellow: rgba(255, 250, 229, 1);

  /*===============================
  Conplus Brand Colors
  ---------------------------------*/

  --white: rgb(255, 255, 255);
  --white-color-76: rgba(255, 255, 255, 0.76);
  --white-color-60: rgba(255, 255, 255, 0.6);
  --white-color-56: rgba(255, 255, 255, 0.56);
  --white-color-20: rgba(255, 255, 255, 0.2);
  --white-color-21: rgba(255, 255, 255, 0.21);
  --white-color-17: rgba(255, 255, 255, 0.17);

  --black-color: rgb(0, 0, 0);
  --black-color-85: rgba(0, 0, 0, 0.85);
  --black-color-80: rgba(0, 0, 0, 0.8);
  --black-color-45: rgba(0, 0, 0, 0.45);
  --black-color-40: rgba(0, 0, 0, 0.4);
  --black-color-36: rgba(0, 0, 0, 0.36);
  --black-color-30: rgba(0, 0, 0, 0.3);
  --black-color-24: rgba(0, 0, 0, 0.24);
  --black-color-21: rgba(0, 0, 0, 0.21);
  --black-color-20: rgba(0, 0, 0, 0.2);
  --black-color-16: rgba(0, 0, 0, 0.16);
  --black-color-15: rgba(0, 0, 0, 0.15);
  --black-color-12: rgba(0, 0, 0, 0.12);
  --black-color-10: rgba(0, 0, 0, 0.1);
  --black-color-07: rgba(0, 0, 0, 0.07);
  --black-color-06: rgba(0, 0, 0, 0.06);
  --black-color-05: rgba(0, 0, 0, 0.05);
  --black-color-03: rgba(0, 0, 0, 0.03);
  --black-color-54: rgba(0, 0, 0, 0.54);
}

.client-app {
  --primary-color: rgb(255, 119, 0);
  --primary-color-85: rgba(255, 119, 0, 0.85);
  --primary-color-70: rgba(255, 119, 0, 0.7);
  --primary-color-50: rgba(255, 119, 0, 0.5);
  --primary-color-40: rgba(255, 119, 0, 0.4);
  --primary-color-20: rgba(255, 119, 0, 0.2);
  --primary-color-16: rgba(255, 119, 0, 0.16);
  --primary-color-15: rgba(255, 119, 0, 0.15);
  --primary-color-12: rgba(255, 119, 0, 0.12);
  --primary-color-10: rgba(255, 119, 0, 0.1);
  --primary-color-06: rgba(255, 119, 0, 0.06);
  --primary-color-05: rgba(255, 119, 0, 0.05);
  --primary-color-03: rgba(255, 119, 0, 0.03);
}
