@import '../includes';

.market-intelligence-card {
  .mat-figure {
    .market-card:hover {
      border: 1px solid $blue-06;
      border-radius: 10px;
    }

    align-items: stretch;
  }
}

.scss-academy-card {
  .mat-figure {
    .market-card:hover {
      border: 1px solid $blue-06;
      border-radius: 10px;
    }
  }
}